import { useState, createContext, useContext, useEffect } from 'react';
import { connect } from 'react-redux';
import swagger from 'accumbo-js-client';
import api from '../../../api/apiClient';
import { showNotification } from '../../../actions';
import { updateObjectInArray } from '../../../utils';

const services = { ...swagger.PartnerService };
delete services.constructFromObject;

type PartnerService = (typeof services)[keyof typeof services];

export type PartnerServiceState = {
  service: PartnerService;
  selected: boolean;
  disabled: boolean;
};

export type SetupCode = {
  id?: number;
  code?: string;
  status?: 'active' | 'used' | 'revoked';
};

type RecommendationInfo = {
  smsContent: string;
  smsReminderContent?: string;
  recommendationReasons: string[];
};

export type Partner = {
  id?: string;
  name?: string;
  facilityCount?: number;
  services?: PartnerServiceState[];
  recommendationInfo?: RecommendationInfo;
  active?: boolean;
  kioskConfiguration?: KioskConfiguration;
  webScreeningConfiguration?: WebScreeningConfiguration;
};

type KioskConfiguration = {
  startScreenImage?: string;
  footerImage?: string;
};

export type WebScreeningConfiguration = {
  urlPath: string;
  logoImage?: string;
  startPage?: StartPage;
  mainCta?: CallToAction;
  secondaryCta?: SecondaryCallToAction;
  lowRisk?: LowRisk;
  highRisk?: HighRisk;
};

type MultilingualString = {
  sv?: string;
  en?: string;
};

type HighRisk = {
  heading: MultilingualString;
  text: MultilingualString;
};

type LowRisk = {
  heading?: MultilingualString;
  text?: MultilingualString;
  imageUrl?: MultilingualString;
};

type CallToAction = {
  url?: string;
  text?: MultilingualString;
};

type SecondaryCallToAction = {
  url: MultilingualString;
  text: MultilingualString;
};

type StartPage = {
  imageUrl?: string;
  heading?: MultilingualString;
  text?: MultilingualString;
};

export type Facility = {
  id?: string;
  name?: string;
  email?: string;
  phoneNumber?: string;
  streetaddress?: string;
  city?: string;
  postalCode?: string;
  partnerRegion?: string;
  partnerCostCenter?: string;
  active?: boolean;
  visitationInfo?: VisitationInfo;
  services?: PartnerServiceState[];
  userCount?: number;
  setupCodes?: SetupCode[];
};

type VisitationInfo = {
  distance?: number;
  cancellationPolicy?: string;
  instructions?: FileInfo;
};

type FileInfo = {
  id: string;
  filename: string;
  contentType: string;
  timestamp: string;
  filePath: string;
  fileUrl: string;
};

export type PartnerUsersResponse = {
  partnerName?: string;
  facilityName?: string;
  users?: PartnerUser[];
};

type ServiceOptions = {
  recommendationReasons?: string[];
};

export type PartnerUser = {
  id?: string;
  givenName?: string;
  familyName?: string;
  title?: string;
  email?: string;
  personalNumber?: string;
  isAdmin?: boolean;
  active?: boolean;
  services?: PartnerServiceState[];
  properties?: PartnerUserProperty[];
  serviceOptions?: ServiceOptions;
};

type PartnerUserProperty = {
  key: string;
  value: string;
};

export type FacilitiesResponse = {
  partnerName?: string;
  facilities?: Facility[];
};

type PartnerContextValue = {
  facilityServices: string[];
  userServices: string[];
  partners: Partner[];
  setPartners(partners: Partner[]): void;
  getPartners(): Promise<Partner[]>;
  getPartner(partnerId: string): Promise<Partner>;
  getPartnerUserExport(partnerId: string): Promise<{ response: FixMe }>;
  createPartner(newPartner: Partner): Promise<Partner>;
  updatePartner(partnerId: string, newPartner: Partial<Partner>): Promise<Partner>;
  updatePartners(updatedPartner: Partner): void;
  removePartner(partnerId: string): Promise<void>;
  addRecommendationInfoToPartner(
    partnerId: string,
    smsContent: string,
    recommendationReasons: string[],
    smsReminderContent: string
  ): Promise<void>;
  getPartnerFacilities(partnerId: string): Promise<FacilitiesResponse>;
  createPartnerFacility(partnerId: string, newFacility: Partner): Promise<Facility>;
  addVisitationInfoToFacility(
    partnerId: string,
    facilityId: string,
    distance?: string,
    filename?: string,
    instructionsFile?: File,
    cancellationPolicy?: string
  ): Promise<void>;
  updateVisitationInfoForFacility(
    partnerId: string,
    facilityId: string,
    distance?: string,
    filename?: string,
    instructionsFile?: File,
    cancellationPolicy?: string
  ): Promise<void>;
  updatePartnerFacility(partnerId: string, facilityId: string, updatedFacility: Partial<Facility>): Promise<Facility>;
  getPartnerFacility(partnerId: string, facilityId: string): Promise<FacilitiesResponse>;
  removePartnerFacility(partnerId: string, facilityId: string): Promise<undefined>;
  getUsers(partnerId: string, facilityId?: string): Promise<PartnerUsersResponse>;
  setFacilityStatus(partnerId: string, facilityId: string, facility: Partial<Facility>): Promise<Facility>;
  getUser(partnerId: string, userId: string): Promise<PartnerUsersResponse>;
  createUser(partnerId: string, newUser: PartnerUser, facilityId?: string): Promise<PartnerUser>;
  updateUser(partnerId: string, userId: string, updatedUser: PartnerUser): Promise<PartnerUser>;
  removeUser(partnerId: string, userId: string): Promise<undefined>;
  getInstructionsFile(partnerId: string, facilityId: string): Promise<FixMe>;
  toast(message: string, type: string): void;
  generateSetupCode(partnerId: string, facilityId: string): Promise<SetupCode>;
  revokeSetupCode(partnerId: string, facilityId: string, code: SetupCode): Promise<SetupCode>;
  sendSetupCode(partnerId: string, facilityId: string, codeId: number): Promise<void>;
  addWebScreeningConfigurationToPartner(
    partnerId: string,
    configuration: Partial<WebScreeningConfiguration>
  ): Promise<void>;
};

export const PartnerContext = createContext<PartnerContextValue>({
  facilityServices: [],
  userServices: [],
  partners: [],
  setPartners: () => {
    return;
  },
  getPartner: () => new Promise((r) => r({})),
  getPartnerUserExport: () => new Promise((r) => r({ response: {} })),
  getPartners: () => new Promise((r) => r([])),
  createPartner: () => new Promise((r) => r({})),
  updatePartner: () => new Promise((r) => r({})),
  updatePartners: () => {
    return;
  },
  addRecommendationInfoToPartner: () => new Promise((r) => r()),
  createPartnerFacility: () => new Promise((r) => r({})),
  addVisitationInfoToFacility: () => new Promise((r) => r()),
  updateVisitationInfoForFacility: () => new Promise((r) => r()),
  updatePartnerFacility: () => new Promise((r) => r({})),
  getPartnerFacility: () => new Promise((r) => r({})),
  getPartnerFacilities: () => new Promise((r) => r({})),
  getUsers: () => new Promise((r) => r({})),
  setFacilityStatus: () => new Promise((r) => r({})),
  getUser: () => new Promise((r) => r({})),
  createUser: () => new Promise((r) => r({})),
  updateUser: () => new Promise((r) => r({})),
  getInstructionsFile: () => new Promise((r) => r(new Response())),
  removePartner: () => new Promise((r) => r(undefined)),
  removePartnerFacility: () => new Promise((r) => r(undefined)),
  removeUser: () => new Promise((r) => r(undefined)),
  toast: () => {
    return;
  },
  generateSetupCode: () => new Promise((r) => r({})),
  revokeSetupCode: () => new Promise((r) => r({})),
  sendSetupCode: () => new Promise((r) => r()),
  addWebScreeningConfigurationToPartner: () => new Promise((r) => r())
});

const PartnerProvider = ({ authToken, showNotification, children }) => {
  const [partners, setPartners] = useState<Partner[]>([]);
  const facilityServices = Object.keys(services).filter((k) => k !== 'admin');
  const userServices = Object.keys(services).filter((k) => k !== 'kiosk');

  useEffect(() => {
    if (!partners.length) {
      api.getPartners(authToken).then(setPartners);
    }
  }, []);

  const getPartners = () => {
    return api.getPartners(authToken);
  };

  const getPartnerFacilities = (partnerId: string) => {
    return api.getPartnerFacilities(authToken, partnerId);
  };

  const getPartner = (partnerId: string) => {
    return api.getPartner(authToken, partnerId);
  };

  const getPartnerUserExport = (partnerId: string) => {
    return api.getPartnerUserExport(authToken, partnerId);
  };

  const createPartner = (newPartner: Partner) => {
    return api.createPartner(authToken, newPartner).then((res) => {
      setPartners([...partners, res]);
      return new Promise((r) => r(res));
    });
  };

  const updatePartner = (partnerId: string, updatedPartner: Partial<Partner>) => {
    return api.updatePartner(authToken, partnerId, updatedPartner);
  };

  const addRecommendationInfoToPartner = (
    partnerId: string,
    smsContent: string,
    recommendationReasons: string[],
    smsReminderContent
  ) => {
    const fixedRecommendationReasons = ['knownHypertension', 'suspectedHypertension'];
    return api.addRecommendationInfoToPartner(
      authToken,
      partnerId,
      smsContent,
      [...fixedRecommendationReasons, ...recommendationReasons],
      smsReminderContent
    );
  };

  const addWebScreeningConfigurationToPartner = (
    partnerId: string,
    configuration: Partial<WebScreeningConfiguration>
  ) => {
    return api.addWebScreeningConfigurationToPartner(authToken, partnerId, configuration);
  };

  const createPartnerFacility = (partnerId: string, newFacility: Partner) => {
    return api.createPartnerFacility(authToken, partnerId, newFacility);
  };

  const addVisitationInfoToFacility = (
    partnerId: string,
    facilityId: string,
    distance?: string,
    filename?: string,
    instructionsFile?: File,
    cancellationPolicy?: string
  ) => {
    return api.addVisitationInfoToPartnerFacility(
      authToken,
      partnerId,
      facilityId,
      distance,
      filename,
      instructionsFile,
      cancellationPolicy
    );
  };

  const updateVisitationInfoForFacility = (
    partnerId: string,
    facilityId: string,
    distance?: string,
    filename?: string,
    instructionsFile?: File,
    cancellationPolicy?: string
  ) => {
    return api.updateVisitationInfoForPartnerFacility(
      authToken,
      partnerId,
      facilityId,
      distance,
      filename,
      instructionsFile,
      cancellationPolicy
    );
  };

  const updatePartnerFacility = (partnerId: string, facilityId: string, updatedFacility: Partial<Facility>) => {
    return api.updatePartnerFacility(authToken, partnerId, facilityId, updatedFacility);
  };

  const getUsers = (partnerId: string, facilityId?: string) => {
    return api.getPartnerUsers(authToken, partnerId, facilityId);
  };

  const getPartnerFacility = (partnerId: string, facilityId: string) => {
    return api.getPartnerFacility(authToken, partnerId, facilityId);
  };

  const setFacilityStatus = async (partnerId: string, facilityId: string, facility: Partial<Facility>) => {
    return api.updatePartnerFacility(authToken, partnerId, facilityId, facility);
  };

  const getUser = (partnerId: string, userId: string) => {
    return api.getPartnerUser(authToken, partnerId, userId);
  };

  const createUser = (partnerId: string, newUser: PartnerUser, facilityId: string) => {
    return api.createPartnerUser(authToken, partnerId, facilityId, newUser);
  };

  const updateUser = (partnerId: string, userId: string, updatedUser: PartnerUser) => {
    return api.updatePartnerUser(authToken, partnerId, userId, updatedUser);
  };

  const getInstructionsFile = (partnerId: string, facilityId: string) => {
    return api.getPartnerFacilityInstructionsFile(authToken, partnerId, facilityId);
  };

  const removePartner = (partnerId: string) => {
    return api.removePartner(authToken, partnerId).then(() => setPartners(partners.filter((p) => p.id !== partnerId)));
  };

  const removePartnerFacility = (partnerId: string, facilityId: string) => {
    return api.removePartnerFacility(authToken, partnerId, facilityId);
  };

  const removeUser = (partnerId: string, userId: string) => {
    return api.removePartnerUser(authToken, partnerId, userId);
  };

  const updatePartners = (updatedPartner: Partner) => {
    setPartners(
      updateObjectInArray(partners, {
        index: partners.findIndex((p) => p.id === updatedPartner.id),
        item: updatedPartner
      })
    );
  };

  const generateSetupCode = (partnerId: string, facilityId: string) => {
    return api.generatePartnerSetupCode(authToken, partnerId, facilityId);
  };

  const revokeSetupCode = (partnerId: string, facilityId: string, code: SetupCode) => {
    return api.updatePartnerSetupCode(authToken, partnerId, facilityId, { ...code, status: 'revoked' });
  };

  const sendSetupCode = (partnerId: string, facilityId: string, codeId: number) => {
    return api.sendPartnerSetupCode(authToken, partnerId, facilityId, codeId);
  };

  const value = {
    facilityServices,
    userServices,
    partners,
    getPartners,
    setPartners,
    updatePartners,
    getPartnerFacilities,
    getPartner,
    getPartnerUserExport,
    createPartner,
    updatePartner,
    addRecommendationInfoToPartner,
    createPartnerFacility,
    addVisitationInfoToFacility,
    updateVisitationInfoForFacility,
    updatePartnerFacility,
    getUsers,
    getPartnerFacility,
    setFacilityStatus,
    getUser,
    createUser,
    updateUser,
    getInstructionsFile,
    removePartner,
    removePartnerFacility,
    removeUser,
    toast: showNotification,
    generateSetupCode,
    revokeSetupCode,
    sendSetupCode,
    addWebScreeningConfigurationToPartner
  };

  return <PartnerContext.Provider value={value}>{children}</PartnerContext.Provider>;
};

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.token.jwt
  };
};

const mapActionsToProps = {
  showNotification
};

export default connect(mapStateToProps, mapActionsToProps)(PartnerProvider);

export function usePartner() {
  return useContext(PartnerContext);
}
