import * as types from '../constants/actionTypes';
import moment from 'moment';
import { loadState } from '../services/localStorage';
import { DATE_FORMAT, REFERRAL_PRINT_OFFSET_SETTINGS_STORAGE_KEY } from '../constants';
import { toggleItem, removeItemFromArray, updateObjectInArray, convertCuffSizeToInt } from '../utils';

const months = [
  {
    value: '01',
    label: 'Januari'
  },
  {
    value: '02',
    label: 'Februari'
  },
  {
    value: '03',
    label: 'Mars'
  },
  {
    value: '04',
    label: 'April'
  },
  {
    value: '05',
    label: 'Maj'
  },
  {
    value: '06',
    label: 'Juni'
  },
  {
    value: '07',
    label: 'Juli'
  },
  {
    value: '08',
    label: 'Augusti'
  },
  {
    value: '09',
    label: 'September'
  },
  {
    value: '10',
    label: 'Oktober'
  },
  {
    value: '11',
    label: 'November'
  },
  {
    value: '12',
    label: 'December'
  }
];

const years = [];
const startYear = 2018;
const currentYear = new Date().getFullYear();
let currentMonth = new Date().getMonth();
let defaultYear = 0;
let defaultMonth = currentMonth - 1;

if (currentMonth === 0) {
  defaultMonth = 11;
  defaultYear = 1;
}

for (let i = currentYear; i >= startYear; i--) {
  years.push({
    label: i,
    value: i
  });
}

const quarters = [
  {
    label: 'okt.–dec.',
    value: 4
  },
  {
    label: 'jul.–sep.',
    value: 3
  },
  {
    label: 'apr.–jun.',
    value: 2
  },
  {
    label: 'jan.–mar.',
    value: 1
  }
];

const invoiceReportDate = `${years[defaultYear].value}-${months[defaultMonth].value}-01`;
const invoiceReportMonth = months[defaultMonth].value;
const invoiceReportYear = years[defaultYear].value;

const initialState = {
  months,
  quarters,
  years,
  currentMonth,
  currentYear,
  defaultYear,
  defaultMonth,
  invoiceReportDate,
  invoiceReportMonth,
  invoiceReportYear,
  offsetSettingsVisible: false,
  shipmentItems: [],
  delayedShipmentItems: [],
  selectedShipmentItems: [],
  currentlyDeletingShipments: [],
  selectDelayDateModalVisible: false,
  updateDelayDateModalVisible: false,
  referrals: [],
  selectedReferrals: [],
  disabledReferrals: [],
  caregiverStatistics: [],
  doctorPatientTotals: [],
  doctorPatientTotalSums: {
    total: -1,
    active: -1,
    hidden: -1,
    blocked: -1,
    externalSource: -1
  },
  doctorFinalNoteBreakdown: {
    meta: null,
    finalNoteTotals: []
  },
  remunerationScheme: {},
  tableOfStrings: { columnNames: [], rows: [[]] }
};

const offsetSettingsDefault = {
  rutin: {
    x: 0,
    y: 0
  },
  special: {
    x: 0,
    y: 0
  }
};

const offsetSettings = loadState(REFERRAL_PRINT_OFFSET_SETTINGS_STORAGE_KEY, true) || offsetSettingsDefault;

initialState.offsetSettings = offsetSettings;

const sortByDelayDate = (a, b) => {
  if (moment(a.delayDate, DATE_FORMAT).isAfter(moment(b.delayDate, DATE_FORMAT))) {
    return 1;
  }

  return -1;
};

const sortByCuffSize = (a, b) => {
  if (!a.cuffSize || !b.cuffSize) {
    return 0;
  }

  if (convertCuffSizeToInt(a.cuffSize) < convertCuffSizeToInt(b.cuffSize)) {
    return 1;
  }

  return -1;
};

const reportsReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SET_INVOICE_REPORT_MONTH:
      return {
        ...state,
        invoiceReportMonth: action.payload,
        invoiceReportDate: `${state.invoiceReportYear}-${action.payload}-01`
      };
    case types.SET_INVOICE_REPORT_YEAR:
      return {
        ...state,
        invoiceReportYear: action.payload,
        invoiceReportDate: `${action.payload}-${state.invoiceReportMonth}-01`
      };
    case types.GENERATE_INVOICE_REPORT_REQUEST:
      return { ...state, loadingInvoiceReport: true };
    case types.GENERATE_INVOICE_REPORT_SUCCESS:
      return { ...state, loadingInvoiceReport: false, invoiceReportError: undefined };
    case types.GENERATE_INVOICE_REPORT_ERROR:
      return { ...state, loadingInvoiceReport: false, invoiceReportError: action.payload };
    case types.SHOW_OFFSET_SETTINGS_MODAL:
      return { ...state, offsetSettingsVisible: true };
    case types.HIDE_OFFSET_SETTINGS_MODAL:
      return { ...state, offsetSettingsVisible: false };
    case types.SET_REFERRAL_PRINT_OFFSET: {
      const { type, dimension, value } = action.payload;
      return {
        ...state,
        offsetSettings: {
          ...state.offsetSettings,
          [type]: {
            ...state.offsetSettings[type],
            [dimension]: value
          }
        }
      };
    }
    case types.GET_REFERRAL_PDFS_REQUEST:
      return { ...state, loadingReferralDocuments: true };
    case types.GET_REFERRAL_PDFS_SUCCESS:
      return {
        ...state,
        selectedReferrals: [],
        disabledReferrals: [...state.disabledReferrals, ...action.payload.disabledReferrals],
        loadingReferralDocuments: false,
        numReferrals: 0,
        referralDocumentsError: undefined
      };
    case types.GET_REFERRAL_PDFS_ERROR:
      return { ...state, loadingReferralDocuments: false, referralDocumentsError: action.payload };
    case types.GET_REFERRAL_TEST_PDFS_REQUEST:
      return { ...state, loadingReferralTestDocuments: true };
    case types.GET_REFERRAL_TEST_PDFS_SUCCESS:
      return { ...state, loadingReferralTestDocuments: false, referralTestDocumentsError: undefined };
    case types.GET_REFERRAL_TEST_PDFS_ERROR:
      return { ...state, loadingReferralTestDocuments: false, referralTestDocumentsError: action.payload };
    case types.SEND_BULK_MESSAGE_REQUEST:
      return { ...state, sendingBulkMessage: true };
    case types.SEND_BULK_MESSAGE_SUCCESS:
      return { ...state, sendingBulkMessage: false, bulkMessageError: undefined };
    case types.SEND_BULK_MESSAGE_ERROR:
      return { ...state, sendingBulkMessage: false, bulkMessageError: action.payload };
    case types.SEND_BULK_REFERRALS_REQUEST:
      return { ...state, sendingBulkReferrals: true };
    case types.SEND_BULK_REFERRALS_SUCCESS:
      return { ...state, sendingBulkReferrals: false, bulkReferralsError: undefined };
    case types.SEND_BULK_REFERRALS_ERROR:
      return { ...state, sendingBulkReferrals: false, bulkReferralsError: action.payload };
    case types.GET_SHIPMENT_ITEMS_REQUEST:
      return { ...state, loadingShipmentItems: true };
    case types.GET_SHIPMENT_ITEMS_SUCCESS:
      return {
        ...state,
        loadingShipmentItems: false,
        shipmentItems: action.payload.filter(
          (shipment) => !shipment.delayDate || moment(shipment.delayDate, DATE_FORMAT).isSameOrBefore(moment())
        ),
        delayedShipmentItems: action.payload
          .filter((shipment) => !!shipment.delayDate && moment(shipment.delayDate, DATE_FORMAT).isAfter(moment()))
          .sort(sortByDelayDate),
        shipmentItemsError: undefined
      };
    case types.GET_SHIPMENT_ITEMS_ERROR:
      return { ...state, loadingShipmentItems: false, shipmentItemsError: action.payload };
    case types.TOGGLE_SHIPMENT_ITEM:
      return { ...state, selectedShipmentItems: toggleItem(state.selectedShipmentItems, action.payload) };
    case types.TOGGLE_ALL_SHIPMENTS_SELECTED:
      return {
        ...state,
        selectedShipmentItems:
          state.selectedShipmentItems.length === state.shipmentItems.length
            ? []
            : state.shipmentItems.map((item) => item.id)
      };
    case types.SCHEDULE_SHIPMENTS_REQUEST:
      return { ...state, schedulingShipments: true };
    case types.SCHEDULE_SHIPMENTS_SUCCESS:
      return {
        ...state,
        schedulingShipments: false,
        shipmentItems: state.shipmentItems.filter((item) => !state.selectedShipmentItems.includes(item.id)),
        selectedShipmentItems: [],
        scheduleShipmentsError: undefined
      };
    case types.SCHEDULE_SHIPMENTS_ERROR:
      return { ...state, schedulingShipments: false, scheduleShipmentsError: action.payload };
    case types.DELETE_SHIPMENT_REQUEST:
      return {
        ...state,
        currentlyDeletingShipments: toggleItem(state.currentlyDeletingShipments, action.payload)
      };
    case types.DELETE_SHIPMENT_SUCCESS:
      return {
        ...state,
        currentlyDeletingShipments: toggleItem(state.currentlyDeletingShipments, action.payload),
        shipmentItems: removeItemFromArray(state.shipmentItems, {
          index: state.shipmentItems.map((item) => item.id).indexOf(action.payload)
        }),
        selectedShipmentItems: removeItemFromArray(state.selectedShipmentItems, {
          index: state.selectedShipmentItems.indexOf(action.payload)
        }),
        deleteShipmentError: undefined
      };
    case types.DELETE_SHIPMENT_ERROR:
      return {
        ...state,
        currentlyDeletingShipments: toggleItem(state.currentlyDeletingShipments, action.payload.shipmentId),
        deleteShipmentError: action.payload.error
      };
    case types.DELAY_SHIPMENT_REQUEST:
      return { ...state, delayingShipment: true };
    case types.DELAY_SHIPMENT_SUCCESS: {
      const delayedShipment = state.shipmentItems.find((shipment) => shipment.id === action.payload.id);
      return {
        ...state,
        delayingShipment: false,
        selectDelayDateModalVisible: false,
        shipmentItems: removeItemFromArray(state.shipmentItems, {
          index: state.shipmentItems.map((item) => item.id).indexOf(action.payload.id)
        }),
        selectedShipmentItems: removeItemFromArray(state.selectedShipmentItems, {
          index: state.selectedShipmentItems.indexOf(action.payload.id)
        }),
        delayedShipmentItems: [
          ...state.delayedShipmentItems,
          { ...delayedShipment, delayDate: action.payload.delayDate }
        ].sort(sortByDelayDate),
        delayShipmentError: undefined
      };
    }
    case types.DELAY_SHIPMENT_ERROR:
      return { ...state, delayingShipment: false, delayShipmentError: action.payload };
    case types.UNDO_DELAY_REQUEST:
      return { ...state, undoingDelay: true };
    case types.UNDO_DELAY_SUCCESS: {
      const undoneDelay = state.delayedShipmentItems.find((shipment) => shipment.id === action.payload.id);
      const updatedShipmentItems =
        action.payload.shipmentType === 'outgoing'
          ? [{ ...undoneDelay, delayDate: action.payload.delayDate }, ...state.shipmentItems]
          : [...state.shipmentItems, { ...undoneDelay, delayDate: action.payload.delayDate }];

      return {
        ...state,
        undoingDelay: false,
        delayedShipmentItems: removeItemFromArray(state.delayedShipmentItems, {
          index: state.delayedShipmentItems.map((item) => item.id).indexOf(action.payload.id)
        }),
        shipmentItems: updatedShipmentItems.sort(sortByCuffSize),
        undoDelayError: undefined
      };
    }
    case types.UNDO_DELAY_ERROR:
      return { ...state, undoingDelay: false, undoDelayError: action.payload };
    case types.UPDATE_DELAY_DATE_REQUEST:
      return { ...state, updatingDelay: true };
    case types.UPDATE_DELAY_DATE_SUCCESS: {
      const updatedItemIndex = state.delayedShipmentItems.findIndex((shipment) => shipment.id === action.payload.id);
      return {
        ...state,
        updatingDelay: false,
        updateDelayDateModalVisible: false,
        delayedShipmentItems: updateObjectInArray(state.delayedShipmentItems, {
          index: updatedItemIndex,
          item: {
            delayDate: action.payload.delayDate
          }
        }).sort(sortByDelayDate),
        updateDelayDateError: undefined
      };
    }
    case types.UPDATE_DELAY_DATE_ERROR:
      return { ...state, updatingDelay: false, updateDelayDateError: action.payload };
    case types.SET_SELECT_DELAY_DATE_MODAL_VISIBILITY:
      return { ...state, selectDelayDateModalVisible: action.visibility };
    case types.UPDATE_DELAY_DATE_MODAL_VISIBILITY:
      return { ...state, updateDelayDateModalVisible: action.visibility };
    case types.GET_REFERRALS_REQUEST:
      return { ...state, loadingReferrals: true };
    case types.GET_REFERRALS_SUCCESS:
      return {
        ...state,
        disabledReferrals: [],
        selectedReferrals: [],
        loadingReferrals: false,
        referrals: action.payload,
        referralsError: undefined
      };
    case types.GET_REFERRALS_ERROR:
      return { ...state, loadingReferrals: false, referralsError: action.payload };
    case types.TOGGLE_REFERRAL_ITEM:
      return { ...state, selectedReferrals: toggleItem(state.selectedReferrals, action.payload) };
    case types.TOGGLE_ALL_REFERRAL_ITEMS:
      return {
        ...state,
        selectedReferrals:
          state.selectedReferrals.length === state.referrals.length
            ? []
            : state.referrals.map((referral) => referral.id)
      };
    case types.GET_CAREGIVER_STATISTICS_REQUEST:
      return { ...state, loadingCaregiverStatistics: true };
    case types.GET_CAREGIVER_STATISTICS_SUCCESS:
      return {
        ...state,
        loadingCaregiverStatistics: false,
        caregiverStatistics: action.payload,
        caregiverStatisticsError: undefined
      };
    case types.GET_CAREGIVER_STATISTICS_ERROR:
      return { ...state, loadingCaregiverStatistics: false, caregiverStatisticsError: action.payload };
    case types.GET_DOCTOR_PATIENT_TOTALS_REQUEST:
      return { ...state, loadingDoctorPatientTotals: true };
    case types.GET_DOCTOR_PATIENT_TOTALS_SUCCESS:
      return {
        ...state,
        loadingDoctorPatientTotals: false,
        doctorPatientTotals: action.payload,
        doctorPatientTotalSums: {
          total: action.payload.reduce((acc, curr) => {
            return acc + curr.patients.total;
          }, 0),
          active: action.payload.reduce((acc, curr) => {
            return acc + curr.patients.active;
          }, 0),
          hidden: action.payload.reduce((acc, curr) => {
            return acc + curr.patients.hidden;
          }, 0),
          blocked: action.payload.reduce((acc, curr) => {
            return acc + curr.patients.blocked;
          }, 0),
          externalSource: action.payload.reduce((acc, curr) => {
            return acc + curr.patients.externalSource;
          }, 0)
        },
        doctorPatientTotalsError: undefined
      };
    case types.GET_DOCTOR_PATIENT_TOTALS_ERROR:
      return { ...state, loadingDoctorPatientTotals: false, doctorPatientTotalsError: action.payload };
    case types.GET_DOCTOR_FINAL_NOTE_BREAKDOWN_REQUEST:
      return { ...state, loadingDoctorFinalNoteBreakdown: true };
    case types.GET_DOCTOR_FINAL_NOTE_BREAKDOWN_SUCCESS:
      return {
        ...state,
        loadingDoctorFinalNoteBreakdown: false,
        doctorFinalNoteBreakdown: action.payload,
        doctorFinalNoteBreakdownError: undefined
      };
    case types.GET_DOCTOR_FINAL_NOTE_BREAKDOWN_ERROR:
      return { ...state, loadingDoctorFinalNoteBreakdown: false, doctorFinalNoteBreakdownError: action.payload };
    case types.CLEAR_DOCTOR_FINAL_NOTE_BREAKDOWN:
      return { ...state, doctorFinalNoteBreakdown: { meta: null, finalNoteTotals: [] } };
    case types.GET_REMUNERATION_SCHEME_SUCCESS:
      return { ...state, remunerationScheme: action.payload };
    default:
      return state;
  }
};

export default reportsReducer;
