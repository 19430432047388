import React, { useState, Fragment } from 'react';
import { connect } from 'react-redux';
import { Translate, I18n } from 'react-redux-i18n';
import moment from 'moment';
import Tooltip from '@material-ui/core/Tooltip';
import EditableSelect from '../../EditableSelect';
import LoaderButton from '../../LoaderButton';
import LoadingIcon from '../../LoadingIcon';
import CanPerform from '../../CanPerform';
import DatePickerWithFallback from '../../DatePickerWithFallback';
import PatientFeeHistory from '../PatientFeeHistory';
import ScheduledCommunication from '../ScheduledCommunication';
import FunnelStatus from '../FunnelStatus';
import Modal from '../../Modal';
import api from '../../../api/apiClient';
import { decamelize } from '../../../utils';
import Generation from '../../Generation';
import ProgramManagement from '../ProgramManagement';
import Card from '../../Card';
import { ARM_CIRCUMFERENCE_ID, CAREGIVER_ROLE, COMMUNICATION_POSTPONE_DAYS, DATE_FORMAT } from '../../../constants';
import {
  assignCaregiver,
  deleteMemberPaymentExemption,
  deleteScheduledCommunication,
  showNotification,
  postMemberPaymentExemption,
  updateMemberAnamnesisAnswer,
  updateMemberPaymentExemption,
  updateMemberState,
  updateMemberSuccess,
  updatePaymentExemption,
  updateScheduledCommunication
} from '../../../actions';
import useSWR from 'swr';
import CaregiverHistoryList from './CaregiverHistoryList';

const MemberDetailsInformation = (props) => {
  const {
    authToken,
    authority,
    members,
    sharedData,
    assignCaregiver,
    deleteMemberPaymentExemption,
    deleteScheduledCommunication,
    showNotification,
    postMemberPaymentExemption,
    updateMemberPaymentExemption,
    updateMemberAnamnesisAnswer,
    updateMemberSuccess,
    updatePaymentExemption,
    updateScheduledCommunication,
    updateMemberState,
    match
  } = props;

  const {
    currentMember,
    assignableDoctors,
    assignableDoctorsError,
    fetchingChatMessages,
    fetchingPaymentHistory,
    hasChatMessages,
    latestMessageRead,
    loadingAssignableDoctors,
    loadingScheduledCommunication,
    memberDevice,
    memberState,
    paymentExemption,
    paymentHistory,
    paymentHistoryError,
    scheduledCommunication,
    scheduledCommunicationError,
    shipmentStatus,
    updatingAnamnesisAnswer,
    monitors,
    monitorSizes,
    funnelStatus,
    loadingFunnelStatus,
    funnelStatusError
  } = members;

  const { fetchingRegions, nurses, regionsError } = sharedData;
  const regions = sharedData.regions.filter((region) => region.id.indexOf('null') === -1);
  const [memberUpdates, setMemberUpdates] = useState({});
  const [caregivers, setCaregivers] = useState({});
  const [ongoingSavingProperties, setOngoingSavingProperties] = useState({});
  const [monitorsModalActive, setMonitorsModalActive] = useState(false);
  const {
    data: previousDoctors = [],
    isLoading: isLoadingPreviousDoctors,
    mutate: mutatePreviousDoctors,
    error: previousDoctorsError
  } = useSWR(`/admin/modules/caregiver-history/${match.params.memberId}?caregiverRole=doctor`);
  const {
    data: previousNurses = [],
    isLoading: isLoadingPreviousNurses,
    mutate: mutatePreviousNurses,
    error: previousNursesError
  } = useSWR(`/admin/modules/caregiver-history/${match.params.memberId}?caregiverRole=nurse`);

  const updateProperty = (event, property) => {
    let updatedValue = event.value;

    if (property === 'region') {
      const selectedRegion = sharedData.regions.filter((region) => region.id === event.value)[0];

      if (selectedRegion) {
        updatedValue = {
          id: selectedRegion.id,
          name: selectedRegion.name
        };
      } else {
        updatedValue = undefined;
      }
    }

    setMemberUpdates({
      ...memberUpdates,
      [property]: updatedValue
    });
  };

  const updateCaregiver = (event, caregiverRole) => {
    const selectedCaregiver = (
      caregiverRole === CAREGIVER_ROLE.DOCTOR ? members.assignableDoctors : sharedData.nurses
    ).filter((caregiver) => caregiver.guid === event.value)[0];

    const updatedValue = {
      guid: selectedCaregiver.guid,
      givenName: selectedCaregiver.givenName,
      familyName: selectedCaregiver.familyName,
      role: caregiverRole
    };

    setCaregivers({
      ...caregivers,
      [caregiverRole]: updatedValue
    });
  };

  const updateState = (stateName, stateValue) => {
    updateMemberState(authToken, match.params.memberId, stateName, stateValue);
  };

  const updateCommunication = (communication) => {
    const updatedCommunication = {
      ...communication,
      scheduledDate: moment(communication.scheduledDate, DATE_FORMAT)
        .add(COMMUNICATION_POSTPONE_DAYS, 'd')
        .format(DATE_FORMAT)
    };

    updateScheduledCommunication(
      authToken,
      match.params.memberId,
      communication.id,
      updatedCommunication,
      communication.scheduledDate
    );
  };

  const deleteCommunication = (communication) => {
    if (window.confirm(I18n.t('member_details.scheduled_communication.confirm_delete'))) {
      deleteScheduledCommunication(authToken, match.params.memberId, communication.id);
    }
  };

  const updatePaymentExemptionLocal = (property, isNative) => {
    return (e) => {
      let value;

      switch (property) {
        case 'cardNumber':
          value = e.target.value;
          break;
        case 'validUntil': {
          let date;
          if (!isNative) {
            date = e;
          } else if (e.target.value) {
            date = moment(e.target.value).toDate();
          }

          value = date;
          break;
        }
        default:
          throw new TypeError(`Unrecognized property: ${property}`);
      }

      updatePaymentExemption(property, value);
    };
  };

  const updateAnamnesisAnswer = (questionId, memberProperty) => {
    updateMemberAnamnesisAnswer(
      authToken,
      match.params.memberId,
      questionId,
      [monitorSizes[memberUpdates[memberProperty]]],
      memberProperty
    );
  };

  const saveNewPropertyValue = (propertyName) => {
    return (e) => {
      e.preventDefault();

      setOngoingSavingProperties({
        ...ongoingSavingProperties,
        [`saving${propertyName}`]: true
      });

      api
        .updateMember(authToken, match.params.memberId, {
          [propertyName]: memberUpdates[propertyName]
        })
        .then((response) => {
          updateMemberSuccess(response);

          let newValue;

          if (propertyName === 'region') {
            newValue = response.region.name;
          }

          showNotification(
            I18n.t(`member_details.updates.${decamelize(propertyName, '_')}.success`, { newValue }),
            'success'
          );

          setOngoingSavingProperties({
            ...ongoingSavingProperties,
            [`saving${propertyName}`]: false
          });

          setMemberUpdates({
            ...memberUpdates,
            [propertyName]: undefined
          });
        })
        .catch((error) => {
          console.log(error);

          setOngoingSavingProperties({
            ...ongoingSavingProperties,
            [`saving${propertyName}`]: false
          });
          if (Object.hasOwnProperty.call(memberUpdates, propertyName)) {
            showNotification(I18n.t(`member_details.updates.${decamelize(propertyName, '_')}.error`), 'error');
          }
        });
    };
  };

  const saveNewCaregiver = (caregiverRole) => {
    return () => {
      assignCaregiver(
        authToken,
        currentMember.guid,
        caregivers[caregiverRole],
        caregiverRole === CAREGIVER_ROLE.DOCTOR ? mutatePreviousDoctors : mutatePreviousNurses
      );
    };
  };

  const savePaymentExemption = () => {
    if (!currentMember.paymentExemption) {
      postMemberPaymentExemption(authToken, match.params.memberId, paymentExemption);
    } else {
      updateMemberPaymentExemption(authToken, match.params.memberId, paymentExemption);
    }
  };

  const deletePaymentExemption = () => {
    if (window.confirm(I18n.t('member_details.payment_exemption_confirm_removal'))) {
      deleteMemberPaymentExemption(authToken, match.params.memberId);
    }
  };

  return (
    <>
      <div className="card-container">
        <div>
          {' '}
          <div className="columns">
            <div className="column">
              <h4>
                <Translate value="member_details.created" />
              </h4>
              {moment(currentMember.createdDate, DATE_FORMAT).format('YYYY-MM-DD')}
            </div>
            <div className="column">
              <h4>
                <Translate value="global.status" />
              </h4>
              {currentMember.serviceStatus === 'hidden' && !currentMember.region ? (
                <span>
                  <Translate value="member_details.not_active_yet" />
                </span>
              ) : (
                <Translate value={`global.service_status.${currentMember.serviceStatus}`} />
              )}
            </div>
            <div className="column">
              <h4>
                <Translate value="member_details.platform" />
              </h4>
              <Translate value={`global.device.${memberDevice.platform || 'UNKNOWN'}`} />
            </div>
            <div className="column">
              <h4>
                <Translate value="member_details.last_login" />
              </h4>
              {currentMember.lastLoginDate
                ? moment(currentMember.lastLoginDate, DATE_FORMAT).format('YYYY-MM-DD')
                : '-'}
            </div>
          </div>
          <div className="columns mb-30">
            <div className="column is-3">
              <h4>
                <Translate value="member_details.monitor_shipment_status" />
              </h4>
              {shipmentStatus?.status ? (
                <>
                  {shipmentStatus?.trackingUrl ? (
                    <a href={shipmentStatus.trackingUrl} target="_blank" rel="noopener noreferrer">
                      {shipmentStatus.status}
                    </a>
                  ) : (
                    <span>{shipmentStatus.status}</span>
                  )}

                  <Tooltip
                    title={
                      <div className="fs-12">
                        <Translate
                          value="member_details.shipment_status_updated"
                          timestamp={moment(shipmentStatus.timestamp, DATE_FORMAT).format('YYYY-MM-DD HH:mm')}
                        />
                      </div>
                    }
                    arrow
                    placement="top"
                  >
                    <span className="ml-5 info-circle small">?</span>
                  </Tooltip>
                </>
              ) : (
                '-'
              )}
            </div>
          </div>
          <h2>
            <Translate value="member_details.biometrics" />
          </h2>
          <div className="columns mb-30">
            <div className="column">
              <h4>
                <Translate value="member_details.arm_circumference" />
              </h4>
              <EditableSelect
                isEditable={true}
                isEditing={true}
                requiredAuthority={authority.managePatients}
                text={currentMember && currentMember.armCircumference}
                options={Object.keys(monitorSizes).map((size) => {
                  return { value: size, label: I18n.t(`global.monitor_size.${size}`) };
                })}
                defaultValue={currentMember && currentMember.armCircumference}
                placeholder={I18n.t('member_details.select_monitor_size')}
                isLoadingOptions={false}
                error={undefined}
                errorI18nKey="member_details.error_fetching_region_list"
                onChange={(e) => updateProperty(e, 'armCircumference')}
                name="member-monitor-size-select"
              />
              {memberUpdates.armCircumference && memberUpdates.armCircumference !== currentMember.armCircumference ? (
                <div className="pos-rel">
                  <div className="editable-value__control active">
                    <LoaderButton
                      onClick={() => updateAnamnesisAnswer(ARM_CIRCUMFERENCE_ID, 'armCircumference')}
                      buttonType="input-attached"
                      isLoading={updatingAnamnesisAnswer}
                    >
                      <Translate value="global.buttons.save" />
                    </LoaderButton>
                  </div>
                </div>
              ) : null}
            </div>
            <div className="column"></div>
            <div className="column"></div>
          </div>
          <h2>
            <Translate value="member_details.testing" />
          </h2>
          <div className="columns mb-30">
            <div className="column">
              <h4>
                <Translate value="member_details.region" />
              </h4>
              <EditableSelect
                isEditable={true}
                isEditing={true}
                requiredAuthority={authority.managePatients}
                text={currentMember.region && currentMember.region.name}
                options={regions.map((region) => {
                  return { value: region.id, label: region.name };
                })}
                defaultValue={currentMember.region && currentMember.region.id}
                placeholder={I18n.t('member_details.choose_region')}
                isLoadingOptions={fetchingRegions}
                error={regionsError}
                errorI18nKey="member_details.error_fetching_region_list"
                onChange={(e) => updateProperty(e, 'region')}
                name="member-region-select"
              />
              {memberUpdates.region && memberUpdates.region.id !== '0' ? (
                <div className="pos-rel">
                  <div className="editable-value__control active">
                    <LoaderButton
                      onClick={saveNewPropertyValue('region')}
                      buttonType="input-attached"
                      isLoading={ongoingSavingProperties?.savingRegion}
                    >
                      <Translate value="global.buttons.save" />
                    </LoaderButton>
                  </div>
                </div>
              ) : null}
            </div>
            <div className="column">
              <h4>
                <Translate value="member_details.latest_lab_results" />
              </h4>
              {currentMember.lastLabResultDate
                ? moment(currentMember.lastLabResultDate, DATE_FORMAT).format('YYYY-MM-DD')
                : '-'}
            </div>
            <div className="column"></div>
          </div>
          <h2>
            <Translate value="member_details.treatment" />
          </h2>
          <div className="columns">
            <div className="column">
              <h4>
                <Translate value="member_details.responsible_doctor" />
              </h4>
              <EditableSelect
                isEditable={currentMember.region && currentMember.responsibleDoctor}
                isEditing={true}
                requiredAuthority="managePatients"
                text={
                  currentMember.responsibleDoctor
                    ? `${currentMember.responsibleDoctor.givenName} ${currentMember.responsibleDoctor.familyName}`
                    : '-'
                }
                options={assignableDoctors.map((doctor) => {
                  return { value: doctor.guid, label: `${doctor.givenName} ${doctor.familyName}` };
                })}
                defaultValue={currentMember.responsibleDoctor && currentMember.responsibleDoctor.guid}
                isLoadingOptions={loadingAssignableDoctors}
                error={assignableDoctorsError}
                errorI18nKey="member_details.error_fetching_pal_list"
                onChange={(e) => updateCaregiver(e, CAREGIVER_ROLE.DOCTOR)}
                name="member-pal-select"
              />
              {caregivers.doctor && caregivers.doctor.guid !== currentMember.responsibleDoctor.guid ? (
                <div className="pos-rel">
                  <div className="editable-value__control active">
                    <LoaderButton
                      onClick={saveNewCaregiver(CAREGIVER_ROLE.DOCTOR)}
                      buttonType="input-attached"
                      isLoading={members.assigningCaregiver}
                    >
                      <Translate value="global.buttons.save" />
                    </LoaderButton>
                  </div>
                </div>
              ) : null}
            </div>
            <div className="column">
              <h4>
                <Translate value="member_details.last_measurement" />
              </h4>
              {currentMember.lastMeasureDate
                ? moment(currentMember.lastMeasureDate, DATE_FORMAT).format('YYYY-MM-DD HH:mm')
                : '-'}
            </div>
            <div className="column">
              <h4>
                <Translate value="member_details.chat_communication_header" />
              </h4>
              {fetchingChatMessages ? (
                <LoadingIcon type="spinner-secondary" size="small" />
              ) : hasChatMessages ? (
                <Translate value={`global.${latestMessageRead ? 'yes' : 'no'}`} />
              ) : (
                <Translate value="member_details.empty_chat" />
              )}
            </div>
          </div>
          <div className="columns mb-30">
            <div className="column">
              <h4>
                <Translate value="member_details.responsible_nurse" />
              </h4>
              <EditableSelect
                isEditable={!!currentMember.responsibleNurse}
                isEditing={true}
                requiredAuthority="managePatients"
                text={
                  currentMember.responsibleNurse
                    ? `${currentMember.responsibleNurse.givenName} ${currentMember.responsibleNurse.familyName}`
                    : '-'
                }
                options={nurses.map((nurse) => {
                  return { value: nurse.guid, label: `${nurse.givenName} ${nurse.familyName}` };
                })}
                defaultValue={currentMember.responsibleNurse && currentMember.responsibleNurse.guid}
                isLoadingOptions={sharedData.loadingNurses}
                error={sharedData.nursesError}
                errorI18nKey="member_details.error_fetching_pas_list"
                onChange={(e) => updateCaregiver(e, CAREGIVER_ROLE.NURSE)}
                name="member-pas-select"
              />
              {caregivers.nurse && caregivers.nurse.guid !== currentMember.responsibleNurse.guid ? (
                <div className="pos-rel">
                  <div className="editable-value__control active">
                    <LoaderButton
                      onClick={saveNewCaregiver(CAREGIVER_ROLE.NURSE)}
                      buttonType="input-attached"
                      isLoading={members.assigningCaregiver}
                    >
                      <Translate value="global.buttons.save" />
                    </LoaderButton>
                  </div>
                </div>
              ) : null}
            </div>
            <div className="column">
              <h4>
                <Translate value="member_details.monitor_list.header" />
              </h4>
              {monitors.length ? (
                <span className="text-button" onClick={() => setMonitorsModalActive(true)}>
                  <Translate value={`global.monitor.${monitors[0].device?.deviceType}`} />
                </span>
              ) : (
                '-'
              )}
            </div>
            <div className="column"></div>
          </div>
          <h2>
            <Translate value="member_details.payment_exemption_header" />
          </h2>
          <div className="columns mb-30">
            <div className="column is-3">
              <h4>
                <Translate value="member_details.payment_exemption_card_number" />
              </h4>
              <CanPerform
                action={authority.managePatients}
                fallbackComponent={() => (
                  <span>{currentMember.paymentExemption ? currentMember.paymentExemption.cardNumber : '-'}</span>
                )}
              >
                <input
                  type="text"
                  value={paymentExemption ? paymentExemption.cardNumber : ''}
                  onChange={updatePaymentExemptionLocal('cardNumber')}
                />
              </CanPerform>
            </div>
            <div className="column is-3">
              <h4>
                <Translate value="member_details.payment_exemption_card_date" />
              </h4>
              <CanPerform
                action={authority.managePatients}
                fallbackComponent={() => (
                  <span>{currentMember.paymentExemption ? currentMember.paymentExemption.validUntil : '-'}</span>
                )}
              >
                <DatePickerWithFallback
                  locale={'sv'}
                  minDate={moment().add(1, 'd').toDate()}
                  maxDate={null}
                  selectedDate={
                    paymentExemption && paymentExemption.validUntil && moment(paymentExemption.validUntil).toDate()
                  }
                  placeholderI18nKey="global.choose_date"
                  onChange={updatePaymentExemptionLocal('validUntil')}
                  onChangeNative={updatePaymentExemptionLocal('validUntil', true)}
                  isValid={
                    paymentExemption && paymentExemption.validUntil
                      ? moment(paymentExemption.validUntil).isAfter(moment())
                      : true
                  }
                />
              </CanPerform>
            </div>
            <div className="column">
              <CanPerform action={authority.managePatients}>
                <h4 className="desktop-only">&nbsp;</h4>
                <div className="flex">
                  {(currentMember.paymentExemption &&
                    currentMember.paymentExemption.cardNumber &&
                    currentMember.paymentExemption.cardNumber !== paymentExemption.cardNumber) ||
                  (!currentMember.paymentExemption && paymentExemption.cardNumber) ||
                  (currentMember.paymentExemption &&
                    currentMember.paymentExemption.validUntil &&
                    currentMember.paymentExemption.validUntil !== paymentExemption.validUntil) ||
                  (!currentMember.paymentExemption && paymentExemption.validUntil) ? (
                    <div className="mr-10 mb-5">
                      <LoaderButton
                        buttonType="secondary"
                        disabled={!paymentExemption.cardNumber || !paymentExemption.validUntil}
                        isLoading={members.updatingPaymentExemption || members.postingPaymentExemption}
                        onClick={savePaymentExemption}
                      >
                        <Translate value="global.buttons.save" />
                      </LoaderButton>
                    </div>
                  ) : null}
                  {currentMember.paymentExemption ? (
                    <div>
                      <LoaderButton
                        buttonType="destructive"
                        isLoading={members.deletingPaymentExemption}
                        onClick={deletePaymentExemption}
                      >
                        <Translate value="global.buttons.remove" />
                      </LoaderButton>
                    </div>
                  ) : null}
                </div>
              </CanPerform>
            </div>
          </div>
          <FunnelStatus
            memberState={memberState}
            funnelStatus={funnelStatus}
            isLoading={loadingFunnelStatus}
            error={funnelStatusError}
            onUpdateState={updateState}
          />
          <Generation legacy>
            <CanPerform action={authority.managePatients}>
              <ScheduledCommunication
                isLoading={loadingScheduledCommunication}
                items={scheduledCommunication}
                error={scheduledCommunicationError}
                onUpdate={updateCommunication}
                onDelete={deleteCommunication}
              />
            </CanPerform>
          </Generation>
          <Generation next>
            <ProgramManagement patientId={currentMember.guid} />
          </Generation>
          <PatientFeeHistory
            member={currentMember}
            history={paymentHistory}
            isLoading={fetchingPaymentHistory}
            error={paymentHistoryError}
          />
          {currentMember.claimId ? (
            <Fragment>
              <h2 className="mt-30">
                <Translate value="member_details.insurance_header" />
              </h2>
              <div className="columns">
                <div className="column">
                  <h4>
                    <Translate value="member_details.claim_id" />
                  </h4>
                  {currentMember.claimId}
                </div>
              </div>
            </Fragment>
          ) : null}
        </div>
      </div>
      <Card
        titleI18nKey="member_details.caregiver_history.doctor.header"
        additionalClass="x-large"
        error={previousDoctorsError}
        errorI18nKey="member_details.caregiver_history.doctor.error"
      >
        <CaregiverHistoryList caregivers={previousDoctors} isLoading={isLoadingPreviousDoctors} type="doctor" />
      </Card>
      <Card
        titleI18nKey="member_details.caregiver_history.nurse.header"
        error={previousNursesError}
        errorI18nKey="member_details.caregiver_history.nurse.error"
        additionalClass="x-large"
      >
        <CaregiverHistoryList caregivers={previousNurses} isLoading={isLoadingPreviousNurses} type="nurse" />
      </Card>
      <Modal
        headerI18nKey="global.bp_monitor"
        actionI18nKey="global.buttons.ok"
        visible={monitorsModalActive}
        onClose={() => setMonitorsModalActive(false)}
        onActionCompleted={() => setMonitorsModalActive(false)}
        actionCompletable={true}
        size="auto"
      >
        <div className="table-container">
          <table className="table w-100">
            <thead>
              <tr>
                <th>
                  <Translate value="member_details.monitor_list.heading.monitor" />
                </th>
                <th>
                  <Translate value="member_details.monitor_list.heading.sent" />
                </th>
                <th>
                  <Translate value="member_details.monitor_list.heading.first_measurement" />
                </th>
                <th>
                  <Translate value="member_details.monitor_list.heading.last_measurement" />
                </th>
              </tr>
            </thead>
            <tbody>
              {monitors.map((deviceUsage, i) => (
                <tr key={i}>
                  <th>
                    <Translate value={`global.monitor.${deviceUsage.device?.deviceType}`} />
                  </th>
                  <td>
                    {deviceUsage.sentTimestamp
                      ? moment(deviceUsage.sentTimestamp, DATE_FORMAT).format('YYYY-MM-DD')
                      : '-'}
                  </td>
                  <td>
                    {deviceUsage.firstUsedTimestamp
                      ? moment(deviceUsage.firstUsedTimestamp, DATE_FORMAT).format('YYYY-MM-DD')
                      : '-'}
                  </td>
                  <td>
                    {deviceUsage.lastUsedTimestamp
                      ? moment(deviceUsage.lastUsedTimestamp, DATE_FORMAT).format('YYYY-MM-DD')
                      : '-'}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    authToken: state.auth.token.jwt,
    authority: state.sharedData.authorityTypes,
    members: state.members,
    sharedData: state.sharedData
  };
};

const mapActionsToProps = {
  assignCaregiver,
  deleteMemberPaymentExemption,
  deleteScheduledCommunication,
  showNotification,
  postMemberPaymentExemption,
  updateMemberState,
  updateMemberAnamnesisAnswer,
  updateMemberPaymentExemption,
  updateMemberSuccess,
  updatePaymentExemption,
  updateScheduledCommunication
};

export default connect(mapStateToProps, mapActionsToProps)(MemberDetailsInformation);
