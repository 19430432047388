import { useEffect, useState } from 'react';
import useSWR, { mutate } from 'swr';
import { I18n, Translate } from 'react-redux-i18n';
import moment from 'moment';
import Card from '../../Card';
import Select from '../../Select';
import { useAbsence } from '../AbsenceProvider';
import RadioButton from '../../RadioButton';
import Button from '../../Button';
import DatePickerWithFallback from '../../DatePickerWithFallback';
import { Link, useHistory, useParams } from 'react-router-dom';
import { APPLICATION_BASE_PATH, NULL_GUID } from '../../../constants';
import { removeItemFromArray, updateObjectInArray } from '../../../utils';
import { ReactComponent as Chevron } from '../../../assets/icons/chevron_currentColor.svg';
import SkeletonComponent from '../../SkeletonComponent';
import styles from './CreateAbsence.module.scss';

export default function CreateAbsence({ editMode }) {
  const history = useHistory();
  const { absenceId } = useParams<{ absenceId: string }>();
  const params = new URL(window.location.toString()).searchParams;
  const caregiverId = params.get('caregiverId');
  const { doctors, nurses, selectedRoleFilter, toast, createAbsence, updateAbsence, deleteAbsence } = useAbsence();
  const caregivers = [...doctors, ...nurses].map((c) => ({
    label: `${c.givenName} ${c.familyName}`,
    value: c.guid,
    caregiverRole: c.caregiverRole,
    numberOfPatients: c.numberOfPatients || 0
  }));
  const [selectedCaregiver, setSelectedCaregiver] = useState<CaregiverSelectOption | undefined>(
    caregivers.find((c) => c.value === caregiverId)
  );
  const [selectedReason, setSelectedReason] = useState('');
  const [fromDate, setFromDate] = useState<Date>();
  const [toDate, setToDate] = useState<Date>();
  const [coveringCaregivers, setCoveringCaregivers] = useState<PatientDistribution[]>([
    { caregiverId: '', percentage: '' }
  ]);
  const {
    data: absence,
    isLoading,
    error
  } = useSWR<CaregiverAbsence>(editMode ? `/admin/modules/caregiver-absence/${absenceId}` : null);

  useEffect(() => {
    if (!editMode) {
      return;
    }

    setSelectedCaregiver(caregivers.find((c) => c.value === absence?.caregiver?.guid));
    if (absence?.reason) {
      setSelectedReason(absence.reason);
    }

    setFromDate(moment(absence?.from).toDate());
    setToDate(moment(absence?.to).toDate());
    if (absence?.coveringCaregivers?.length) {
      setCoveringCaregivers(absence?.coveringCaregivers);
    }
  }, [absence, doctors, nurses]);

  const caregiverOptions = [
    {
      caregiverRole: selectedCaregiver?.caregiverRole,
      label: I18n.t('manage_absence.random_assignment'),
      value: NULL_GUID
    },
    ...caregivers
  ];
  const totalPossibleSelections = caregiverOptions.filter(
    (c) => c.caregiverRole === selectedCaregiver?.caregiverRole && c.value !== selectedCaregiver?.value
  ).length;

  const recipientOptions = caregiverOptions.filter(
    (c) =>
      c.value !== selectedCaregiver?.value &&
      c.caregiverRole === selectedCaregiver?.caregiverRole &&
      !coveringCaregivers.map((co) => co.caregiverId).includes(c.value)
  );

  const reasons = ['VACATION', 'SICK_LEAVE', 'EDUCATION', 'PARENTAL_LEAVE', 'LEAVE_OF_ABSENCE'];
  const allocationSum = coveringCaregivers.reduce((acc, curr) => acc + Number(curr.percentage), 0);
  const hasStarted = editMode && moment().isSameOrAfter(moment(fromDate));

  const save = async () => {
    if (!selectedCaregiver?.value) {
      return;
    }

    const dateFormat = 'YYYY-MM-DD';
    const absenceUpdates: Partial<CaregiverAbsence> = {
      reason: selectedReason as AbsenceReason,
      from: moment(fromDate).format(dateFormat),
      to: moment(toDate).format(dateFormat),
      coveringCaregivers: coveringCaregivers.map((c) => ({
        caregiverId: c.caregiverId,
        percentage: Number(c.percentage)
      }))
    };

    let res;
    if (editMode && absence?.id) {
      res = await updateAbsence(absence.id, { ...absenceUpdates });
    } else {
      res = await createAbsence(selectedCaregiver?.value, absenceUpdates);
    }

    if (!res.ok) {
      toast(I18n.t('notification.save_absence.error'), 'error');
      return;
    }

    toast(I18n.t('notification.save_absence.success'), 'success');
    mutate(`/admin/modules/caregiver-absence${selectedRoleFilter ? `?role=${selectedRoleFilter.value}` : ''}`);

    if (caregiverId) {
      mutate(`/admin/modules/caregiver-absence?caregiver=${caregiverId}`);
    }

    if (editMode) {
      mutate(`/admin/modules/caregiver-absence/${absenceId}`);
    }

    let redirectUrl: string;
    if (caregiverId) {
      redirectUrl = `/${APPLICATION_BASE_PATH}/user/${caregiverId}/planned-absence`;
    } else {
      redirectUrl = `/${APPLICATION_BASE_PATH}/planning/manage-absence`;
    }
    history.replace(redirectUrl);
  };

  const cancelAbsence = async () => {
    if (!absenceId) {
      return;
    }

    if (window.confirm(I18n.t('manage_absence.confirm_remove_absence'))) {
      const res = await deleteAbsence(absenceId);

      if (!res.ok) {
        toast(I18n.t('notification.remove_absence.error'), 'error');
        return;
      }

      toast(I18n.t('notification.remove_absence.success'), 'success');
      mutate(`/admin/modules/caregiver-absence${selectedRoleFilter ? `?role=${selectedRoleFilter.value}` : ''}`);
      history.replace(`/${APPLICATION_BASE_PATH}/planning/manage-absence`);
    }
  };

  const addCoveringCaregiver = () => {
    setCoveringCaregivers([...coveringCaregivers, { caregiverId: '', percentage: '' }]);
  };

  const handleCoveringCaregiverSelection = (index) => (e) => {
    setCoveringCaregivers(updateObjectInArray(coveringCaregivers, { index, item: { caregiverId: e.value } }));
  };

  const removeCoveringCaregiver = (index) => () => {
    setCoveringCaregivers(removeItemFromArray(coveringCaregivers, { index }));
  };

  const updatePercentageForCoveringCaregiver = (index) => (e) => {
    setCoveringCaregivers(
      updateObjectInArray(coveringCaregivers, { index, item: { percentage: e.target.value.replace(/\D/g, '') } })
    );
  };

  return (
    <Card titleI18nKey={`manage_absence.${editMode ? 'edit' : 'create'}_heading`} additionalClass="x-large">
      {isLoading ? (
        <div className="stack-2">
          <div>
            <SkeletonComponent height="17px" width="200px" />
            <SkeletonComponent height="43px" width="100%" />
          </div>
          <div>
            <SkeletonComponent height="17px" width="200px" />
            <SkeletonComponent height="17px" width="230px" count={5} />
          </div>
          <div>
            <SkeletonComponent height="17px" width="200px" />
            <SkeletonComponent height="43px" width="100%" />
          </div>
          <div>
            <div className="flex">
              <div className="mr-10">
                <SkeletonComponent height="17px" width="50px" />
                <SkeletonComponent height="43px" width="150px" />
              </div>
              <div>
                <SkeletonComponent height="17px" width="50px" />
                <SkeletonComponent height="43px" width="150px" />
              </div>
            </div>
          </div>
        </div>
      ) : !error ? (
        <div className="stack-2">
          <div>
            {hasStarted ? <Translate value="manage_absence.absence_ongoing" className={styles.notice} /> : null}
          </div>
          <div>
            <h4>
              <Translate value="global.caregiver" />
            </h4>
            <div className={styles.caregiverWrapper}>
              <Select
                placeholder={I18n.t('global.choose_caregiver')}
                isSearchable={false}
                value={selectedCaregiver}
                onChange={setSelectedCaregiver}
                classNamePrefix="single-select"
                options={caregivers}
                isDisabled={hasStarted || editMode}
              />
              {selectedCaregiver ? (
                <div className={styles.patientCount}>
                  <Translate
                    value="manage_absence.responsible_for_n_patients"
                    role={selectedCaregiver.caregiverRole === 'doctor' ? 'PAL' : 'PAS'}
                    numPatients={selectedCaregiver.numberOfPatients.toLocaleString()}
                  />
                </div>
              ) : null}
            </div>
          </div>
          <div>
            <h4>
              <Translate value="manage_absence.absence_reason_heading" />
            </h4>
            {reasons.map((reason) => (
              <div key={reason} className="mb-5">
                <RadioButton
                  label={I18n.t(`manage_absence.reason.${reason.toLowerCase()}`)}
                  name="reason"
                  value={reason}
                  onChange={(e) => setSelectedReason(e.target.value)}
                  checked={selectedReason === reason}
                  disabled={hasStarted}
                  className={hasStarted && selectedReason === reason ? 'fw-bold' : undefined}
                />
              </div>
            ))}
          </div>
          <div className="flex">
            <div className="mr-20">
              <h4>
                <Translate value="global.from_date_incl" />
              </h4>
              <DatePickerWithFallback
                locale="sv"
                selectedDate={fromDate}
                maxDate={null}
                minDate={moment().toDate()}
                selectsStart
                startDate={fromDate}
                endDate={toDate}
                placeholderI18nKey="global.choose_date"
                onChange={setFromDate}
                onChangeNative={(e) =>
                  e.target.value ? setFromDate(moment(e.target.value).toDate()) : setFromDate(fromDate)
                }
                disabled={hasStarted}
              />
            </div>
            <div>
              <h4>
                <Translate value="global.to_date_incl" />
              </h4>
              <DatePickerWithFallback
                locale="sv"
                selectedDate={toDate}
                maxDate={null}
                minDate={fromDate}
                selectsEnd
                startDate={fromDate}
                endDate={toDate}
                placeholderI18nKey="global.choose_date"
                onChange={setToDate}
                onChangeNative={(e) =>
                  e.target.value ? setToDate(moment(e.target.value).toDate()) : setToDate(toDate)
                }
              />
            </div>
          </div>
          {selectedCaregiver ? (
            <div>
              <div className="flex space-between">
                <h4>
                  <Translate value="manage_absence.move_patients_heading" />
                </h4>
                <Button
                  buttonType="text"
                  onClick={addCoveringCaregiver}
                  disabled={coveringCaregivers.length === totalPossibleSelections}
                >
                  <Translate value="manage_absence.add_more" />
                </Button>
              </div>
              {coveringCaregivers.map((c, i) => (
                <div className={styles.selectionWrapper} key={c.caregiverId || i}>
                  <Select
                    placeholder={I18n.t('global.choose_caregiver')}
                    isSearchable={false}
                    value={caregiverOptions.find((o) => o.value === c.caregiverId)}
                    onChange={handleCoveringCaregiverSelection(i)}
                    classNamePrefix="single-select"
                    options={recipientOptions}
                  />
                  <div className={styles.inputWrapper}>
                    <input
                      type="text"
                      placeholder={I18n.t('manage_absence.percentage')}
                      value={coveringCaregivers[i].percentage}
                      onChange={updatePercentageForCoveringCaregiver(i)}
                    />
                  </div>
                  {coveringCaregivers[i].percentage ? (
                    <Translate
                      value="manage_absence.num_patients"
                      numPatients={Math.round(
                        (Number(coveringCaregivers[i].percentage) / 100) * selectedCaregiver.numberOfPatients
                      ).toLocaleString()}
                      className={styles.patientCount}
                    />
                  ) : null}
                  {i > 0 ? (
                    <Button buttonType="text" onClick={removeCoveringCaregiver(i)}>
                      <Translate value="global.buttons.remove" />
                    </Button>
                  ) : null}
                </div>
              ))}
              {allocationSum ? (
                <div className="mt-20">
                  <Translate value="manage_absence.total_share" />:{' '}
                  <span className={allocationSum > 100 ? 'negative fw-600' : undefined}>{allocationSum} %</span>
                </div>
              ) : null}
            </div>
          ) : null}
          <div className="flex space-between">
            {selectedCaregiver && !hasStarted ? (
              <Translate value="manage_absence.count_notice" className={styles.notice} />
            ) : (
              <span></span>
            )}
            <div className="space-x-1">
              {editMode ? (
                <Button buttonType="destructive" onClick={cancelAbsence}>
                  <Translate value="manage_absence.remove_absence" />
                </Button>
              ) : null}
              <Button
                disabled={
                  !selectedCaregiver ||
                  !selectedReason ||
                  !fromDate ||
                  !toDate ||
                  !coveringCaregivers.filter((c) => c.caregiverId && c.percentage).length ||
                  allocationSum > 100 ||
                  allocationSum === 0
                }
                onClick={save}
              >
                <Translate value={`global.buttons.${editMode ? 'save' : 'add'}`} />
              </Button>
            </div>
          </div>
        </div>
      ) : (
        <div>
          <div className="error-message fw-bold">
            <Translate value={`manage_absence.${error?.status === 404 ? 'absence_not_found' : 'generic_error'}`} />
          </div>
          <div className="mt-10">
            <Link to={`/${APPLICATION_BASE_PATH}/planning/manage-absence`} className="flex vertical-align">
              <Chevron style={{ transform: 'rotate(90deg)', marginRight: '5px' }} />
              <strong>
                <Translate value="global.back" />
              </strong>
            </Link>
          </div>
        </div>
      )}
    </Card>
  );
}
