import { CURRENT_ENV } from '../constants';
import { v4 as uuidv4 } from 'uuid';
import { convertCannedMessageNewLines } from '../utils';

const apiFactory = require('accumbo-js-client');
const metadata = require('../metadata.json');

const superagent = require('superagent');
const client = new apiFactory.ApiClient();
export const env = process.env.REACT_APP_API_ENV || CURRENT_ENV;
export const basePath = metadata.environments[env].apiUrl.replace(/\/+$/, '');
client.basePath = basePath;
client.defaultHeaders['x-accumbo-api-version'] = '1.3.115';

const userAgent = navigator.userAgent;

const regionSkaneId = '05d07812-7cc0-11e9-9348-342eb68fac74';

function logInTest(data) {
  const authApi = new apiFactory.AuthorizationApi(client);
  const loginRequest = new apiFactory.LoginRequest.constructFromObject(data);
  const opts = {};

  return authApi.loginUsingPOST(userAgent, loginRequest, opts);
}

function challenge(identifier) {
  const authorizationApi = new apiFactory.AuthorizationApi(client);
  const challengeObj = {
    identifier: identifier,
    method: 'bankid'
  };
  const challengeRequest = new apiFactory.ChallengeRequest.constructFromObject(challengeObj);
  const opts = {};

  return authorizationApi.challengeUsingPOST(userAgent, challengeRequest, opts);
}

function login(refToken, identifier) {
  const authorizationApi = new apiFactory.AuthorizationApi(client);
  const loginObj = {
    method: 'bankid',
    identifier
  };

  const opts = {
    authorization: `Token ${refToken}`
  };

  const loginRequest = new apiFactory.LoginRequest.constructFromObject(loginObj);

  return authorizationApi.loginUsingPOST(userAgent, loginRequest, opts);
}

function refreshToken(jwtToken) {
  console.log('refreshing token');
  const authorizationApi = new apiFactory.AuthorizationApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return authorizationApi.loginUsingGET(userAgent, opts);
}

function getMembers(jwtToken, query, offset, limit, sort = 'familyName:asc,givenName:asc', extraFields) {
  const membersListApi = new apiFactory.ListMembersApi(client);

  const options = {
    authorization: `Bearer ${jwtToken}`,
    q: query,
    offset,
    limit,
    sort
  };

  if (extraFields) {
    options.extraFields = extraFields.join(',');
  }

  return membersListApi.getMembersUsingGET(userAgent, options);
}

function getMember(jwtToken, memberGuid) {
  const memberUserProfileApi = new apiFactory.MemberUserProfileApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return memberUserProfileApi.getMemberByGUIDUsingGET(userAgent, memberGuid, opts);
}

function updateMember(jwtToken, memberGuid, updatedMemberFields) {
  const memberUserProfileApi = new apiFactory.MemberUserProfileApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return memberUserProfileApi.updateMemberUsingPATCH(userAgent, memberGuid, updatedMemberFields, opts);
}

function updateMemberAnamnesisAnswer(jwtToken, memberGuid, questionId, newAnwer) {
  const memberHealthProfileApi = new apiFactory.MemberHealthProfileApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return memberHealthProfileApi.postAnamnesisAnswerUsingPOST(userAgent, memberGuid, questionId, newAnwer, opts);
}

function getMemberAnamnesisQuestion(jwtToken, memberGuid, chapterId, questionId) {
  const memberHealthProfileApi = new apiFactory.MemberHealthProfileApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return memberHealthProfileApi.getHealthProfileQuestionUsingGET(userAgent, memberGuid, chapterId, questionId, opts);
}

function getMemberDevices(jwtToken, guid) {
  const memberMobileDevicesApi = new apiFactory.MemberMobileDevicesApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  const deviceId = '00000000-0000-0000-0000-000000000000'; // Only ID supported before multi device support

  return memberMobileDevicesApi.getMobileDeviceUsingGET(userAgent, guid, deviceId, opts);
}

function getSystemUsers(jwtToken, query, offset, limit, sort = 'familyName:asc,givenName:asc', extraFields) {
  const userAdminApi = new apiFactory.UserAdminApi(client);

  const options = {
    authorization: `Bearer ${jwtToken}`,
    q: query,
    offset,
    limit,
    sort
  };

  if (extraFields) {
    options.extraFields = extraFields.join(',');
  }

  return userAdminApi.getSystemUsersUsingGET(userAgent, options);
}

function getSystemUser(jwtToken, guid) {
  const userAdminApi = new apiFactory.UserAdminApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return userAdminApi.getSystemUserByGUIDUsingGET(userAgent, guid, opts);
}

function updateSystemUser(jwtToken, guid, updatedFields) {
  const userAdminApi = new apiFactory.UserAdminApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return userAdminApi.updateSystemUserUsingPATCH(userAgent, guid, updatedFields, opts);
}

function getRegions(jwtToken) {
  const regionsApi = new apiFactory.RegionsApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return regionsApi.getRegionsUsingGET(userAgent, opts);
}

function getInvoiceReport(jwtToken, date) {
  const invoiceDataApi = new apiFactory.InvoiceDataApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return invoiceDataApi.getInvoicereportUsingGET(userAgent, date, 'privera', opts);
}

function getReferralDocuments(jwtToken, offsetSettings, referralIds, isTest = false) {
  const paperReferralsApi = new apiFactory.PaperReferralsApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  const referralsRequest = {
    region: regionSkaneId,
    dryRun: isTest,
    referralIds,
    offset1: [offsetSettings.rutin.x * 10, offsetSettings.rutin.y * 10],
    offset2: [offsetSettings.special.x * 10, offsetSettings.special.y * 10]
  };

  return paperReferralsApi.generateReferralsZipUsingPOSTWithHttpInfo(userAgent, referralsRequest, opts);
}

function getAuthorities() {
  const authorities = { ...apiFactory.Authority };
  delete authorities.constructFromObject;
  return new Promise((resolve) => {
    resolve(Object.getOwnPropertyNames(authorities));
  });
}

function bulkSendChatMessage(jwtToken, caregivers, messageText) {
  const adminChatMessagesApi = new apiFactory.AdminChatMessagesApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  const message = {
    caregivers,
    text: messageText
  };

  return adminChatMessagesApi.postMultiChatMessageUsingPOST(userAgent, message, opts);
}

function postHandover(jwtToken, startDate, caregiverGuid, patientDistribution) {
  const absence = {
    from: startDate,
    coveringCaregivers: patientDistribution,
    reason: 'PERMANENT'
  };

  return fetch(`${basePath}/admin/modules/caregiver-absence/${caregiverGuid}`, {
    headers: {
      authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json'
    },
    method: 'POST',
    body: JSON.stringify(absence)
  });
}

function deleteHandover(jwtToken, vacationId) {
  const vacationAdministrationApi = new apiFactory.VacationAdministrationApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return vacationAdministrationApi.deleteVacationUsingDELETE(userAgent, vacationId, opts);
}

function updateHandover(jwtToken, updatedVacation) {
  const vacationAdministrationApi = new apiFactory.VacationAdministrationApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return vacationAdministrationApi.updateVacationUsingPUT(userAgent, updatedVacation.id, updatedVacation, opts);
}

function getHandovers(jwtToken, caregiverGuid) {
  const vacationAdministrationApi = new apiFactory.VacationAdministrationApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return vacationAdministrationApi.getVacationsUsingGET(userAgent, caregiverGuid, opts);
}

function postBulkReferrals(jwtToken, personalNumbers, messageText) {
  const adminChatMessagesApi = new apiFactory.AdminChatMessagesApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  const message = {
    personalNumbers,
    text: messageText
  };

  return adminChatMessagesApi.postMultiReferralMessageUsingPOST(userAgent, message, opts);
}

function getMemberComment(jwtToken, memberGuid) {
  const memberAdminApi = new apiFactory.MemberAdminApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return memberAdminApi.getAdminMemberCommentUsingGET(userAgent, memberGuid, opts);
}

function updateMemberComment(jwtToken, memberGuid, memberComment) {
  const memberAdminApi = new apiFactory.MemberAdminApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return memberAdminApi.updateAdminMemberCommentUsingPUT(userAgent, memberGuid, memberComment, opts);
}

function getFile(jwtToken, memberGuid, fileId, maxWidth, maxHeight) {
  const filesApi = new apiFactory.FilesApi(client);

  const opts = {
    authorization: `Bearer ${jwtToken}`,
    maxWidth,
    maxHeight
  };

  return filesApi.getFileUsingGETWithHttpInfo(userAgent, 'secured', memberGuid, fileId, opts);
}

function getShipmentItems(jwtToken) {
  const shippingAdministrationApi = new apiFactory.ShippingAdministrationApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return shippingAdministrationApi.getShipmentsUsingGET(userAgent, opts);
}

function scheduleShipments(jwtToken, shipmentIds) {
  const shippingAdministrationApi = new apiFactory.ShippingAdministrationApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return shippingAdministrationApi.transferShipmentsUsingPOST(userAgent, shipmentIds, opts);
}

function addManualShipment(jwtToken, memberGuid, shipmentType, shipmentReason) {
  const shippingAdministrationApi = new apiFactory.ShippingAdministrationApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  const shipment = {
    guid: memberGuid,
    shipmentType
  };

  if (shipmentReason) {
    shipment.reason = shipmentReason;
  }

  return shippingAdministrationApi.addShipmentUsingPOST(userAgent, shipment, opts);
}

function deleteShipment(jwtToken, shipmentId) {
  const shippingAdministrationApi = new apiFactory.ShippingAdministrationApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return shippingAdministrationApi.deleteShipmentUsingDELETE(userAgent, shipmentId, opts);
}

function delayShipment(jwtToken, shipment, delayDate) {
  const shippingAdministrationApi = new apiFactory.ShippingAdministrationApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return shippingAdministrationApi.updateShipmentUsingPATCH(userAgent, shipment.id, { ...shipment, delayDate }, opts);
}

function getMemberJiraIssues(jwtToken, memberGuid) {
  const jiraApi = new apiFactory.JiraIntegrationApi(client);

  const opts = {
    authorization: `Bearer ${jwtToken}`,
    guid: memberGuid
  };

  return jiraApi.getJiraIssuesUsingGET(userAgent, opts);
}

function postMemberJiraIssue(jwtToken, jiraIssue) {
  const jiraApi = new apiFactory.JiraIntegrationApi(client);

  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return jiraApi.createJiraIssueUsingPOST(userAgent, jiraIssue, opts);
}

function getJiraIssueTypes(jwtToken, project) {
  const jiraApi = new apiFactory.JiraIntegrationApi(client);

  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return jiraApi.getJiraIssueTypesUsingGET(userAgent, project, opts);
}

function getReferrals(jwtToken, regionId) {
  const paperReferralsApi = new apiFactory.PaperReferralsApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return paperReferralsApi.getReferralsToPrintUsingGET(userAgent, regionId, opts);
}

function getScheduledCommunication(jwtToken, memberGuid) {
  const scheduledCommunicationApi = new apiFactory.MemberAdminScheduledCommunicationApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return scheduledCommunicationApi.getMemberScheduledCommunicationsUsingGET(userAgent, memberGuid, opts);
}

function updateScheduledCommunication(jwtToken, memberGuid, communicationId, updatedCommunication) {
  const scheduledCommunicationApi = new apiFactory.MemberAdminScheduledCommunicationApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return scheduledCommunicationApi.updateMemberScheduledCommunicationUsingPATCH(
    userAgent,
    memberGuid,
    communicationId,
    updatedCommunication,
    opts
  );
}

function deleteScheduledCommunication(jwtToken, memberGuid, communicationId) {
  const scheduledCommunicationApi = new apiFactory.MemberAdminScheduledCommunicationApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return scheduledCommunicationApi.deleteScheduledCommunicationUsingDELETE(
    userAgent,
    memberGuid,
    communicationId,
    opts
  );
}

function getCannedResponses(jwtToken) {
  return superagent
    .get(`${metadata.environments[env].apiUrl.replace(/\/+$/, '')}/caregiver/chatresponse`)
    .set('Authorization', `Bearer ${jwtToken}`);
}

function updateCannedResponse(jwtToken, newCannedResponse) {
  const cannedChatResponsesApi = new apiFactory.CannedChatResponsesApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  if (!newCannedResponse.isCategory) {
    newCannedResponse.body = convertCannedMessageNewLines(newCannedResponse.body);
  }

  return cannedChatResponsesApi.updateChatResponseUsingPUT(userAgent, newCannedResponse.id, newCannedResponse, opts);
}

function postCannedResponse(jwtToken, cannedResponse) {
  const cannedChatResponsesApi = new apiFactory.CannedChatResponsesApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  delete cannedResponse.isNew;
  delete cannedResponse.path;

  if (!cannedResponse.isCategory) {
    cannedResponse.body = convertCannedMessageNewLines(cannedResponse.body);
  }

  return cannedChatResponsesApi.createChatResponseUsingPOST(userAgent, cannedResponse, opts);
}

function deleteCannedResponse(jwtToken, cannedResponseId) {
  const cannedChatResponsesApi = new apiFactory.CannedChatResponsesApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return cannedChatResponsesApi.deleteChatResponseUsingDELETE(userAgent, cannedResponseId, opts);
}

function getTemplateTags(jwtToken) {
  const cannedChatResponsesApi = new apiFactory.CannedChatResponsesApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return cannedChatResponsesApi.getChatResponseMetatagsUsingGET(userAgent, opts);
}

function sendEmail(jwtToken, memberGuid, subject, body) {
  const memberEmailApi = new apiFactory.MemberEmailApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  const email = {
    subject,
    body
  };

  return memberEmailApi.sendEmailToMemberUsingPOST(userAgent, memberGuid, email, opts);
}

function getCaregiverStatistics(jwtToken, year, caregiverRole) {
  const caregiverStatisticsApi = new apiFactory.CaregiverStatisticsApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`,
    caregiverRole
  };

  return caregiverStatisticsApi.getFinalNoteStatisticsUsingGET(userAgent, year, opts);
}

function getDoctorPatientTotals(jwtToken) {
  const caregiverStatisticsApi = new apiFactory.CaregiverStatisticsApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return caregiverStatisticsApi.getPatientTotalsUsingGET(userAgent, opts);
}

function getDoctorFinalNoteBreakdown(jwtToken, guid, baseDate) {
  const caregiverStatisticsApi = new apiFactory.CaregiverStatisticsApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`,
    baseDate
  };

  return caregiverStatisticsApi.getFinalNoteBreakdownUsingGET(userAgent, guid, opts);
}

function getMemberChatMessages(jwtToken, memberGuid) {
  const memberChatApi = new apiFactory.MemberChatApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return memberChatApi.getChatMessagesUsingGET(userAgent, memberGuid, opts);
}

function getMemberJournal(jwtToken, memberGuid, periodDays) {
  const journalApi = new apiFactory.JournalApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return journalApi.getJournalToPrintUsingGETWithHttpInfo(userAgent, memberGuid, periodDays, opts);
}

function postAssignmentPriority(jwtToken, guid, assignmentPriority) {
  const userAdminApi = new apiFactory.UserAdminApi(client);

  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return userAdminApi.postSystemUserAssignmentPriorityUsingPOST(userAgent, guid, assignmentPriority, opts);
}

function deleteAssignmentPriority(jwtToken, guid) {
  const userAdminApi = new apiFactory.UserAdminApi(client);

  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return userAdminApi.deleteSystemUserAssignmentPriorityUsingDELETE(userAgent, guid, opts);
}

function getMemberSparData(jwtToken, memberGuid) {
  const memberAdminApi = new apiFactory.MemberAdminApi(client);

  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return memberAdminApi.getAdminMemberSparUpdateUsingGET(userAgent, memberGuid, opts);
}

function postMemberPaymentExemption(jwtToken, memberGuid, paymentExemption) {
  const paymentExemptionApi = new apiFactory.FrikortApi(client);

  const opts = {
    authorization: `Bearer ${jwtToken}`,
    paymentExemption
  };

  return paymentExemptionApi.postMemberPaymentExemptionUsingPOST(userAgent, memberGuid, opts);
}

function updateMemberPaymentExemption(jwtToken, memberGuid, paymentExemption) {
  const paymentExemptionApi = new apiFactory.FrikortApi(client);

  const opts = {
    authorization: `Bearer ${jwtToken}`,
    paymentExemption
  };

  return paymentExemptionApi.patchMemberPaymentExemptionUsingPATCH(userAgent, memberGuid, opts);
}

function deleteMemberPaymentExemption(jwtToken, memberGuid) {
  const paymentExemptionApi = new apiFactory.FrikortApi(client);

  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return paymentExemptionApi.deleteMemberPaymentExemptionUsingDELETE(userAgent, memberGuid, opts);
}

function getRemunerationScheme() {
  return new Promise((resolve) => {
    resolve({
      newMemberCheckup: 150,
      threeMonthCheckup: 150,
      yearlyCheckup: 150,
      extraCheckup: 150,
      sixWeekCheckup: 150,
      initialCheckup: 150,
      diagnosisScreening: 150,
      examinationFollowUp: 150
    });
  });
}

function getMemberPaymentHistory(jwtToken, memberGuid) {
  const paymentHistoryApi = new apiFactory.PaymentHistoryApi(client);

  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return paymentHistoryApi.getPaymentHistoryUsingGET(userAgent, memberGuid, opts);
}

function getInvoice(jwtToken, paymentId) {
  const paymentApi = new apiFactory.PaymentApi(client);

  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return paymentApi.getInvoicePdfUsingGETWithHttpInfo(userAgent, paymentId, opts);
}

function assignCaregiver(jwtToken, memberGuid, caregiverGuid) {
  const caregiverApi = new apiFactory.CaregiverApi(client);

  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return caregiverApi.setCaregiverForMemberUsingPOST(userAgent, memberGuid, { uuid: caregiverGuid }, opts);
}

function uploadFile(jwtToken, memberGuid, filename, file, contentType) {
  const memberFilesApi = new apiFactory.MemberFilesApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };
  const fileId = uuidv4();

  return memberFilesApi.postMemberFilesUsingPOST(userAgent, memberGuid, fileId, filename, file, contentType, opts);
}

function tagPersonalNumbersWithSource(jwtToken, invites, source, campaign) {
  const tagReferredPatientApi = new apiFactory.TagReferredPatientApi(client);

  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  const externalPatientReferral = {
    invites,
    source
  };

  if (campaign) {
    externalPatientReferral.campaign = campaign;
  }

  return tagReferredPatientApi.tagPersonalNumberWithSourceUsingPOST(userAgent, externalPatientReferral, opts);
}

function createUser(jwtToken, user, systemUserExtras) {
  const createUserApi = new apiFactory.CreateUserApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  const userToCreate = {
    user: { ...user, personalNumber: user.personalNumber.replace('-', '') },
    systemUserExtras
  };

  return createUserApi.postSystemUserUsingPOST(userAgent, userToCreate, opts);
}

function getMemberShipmentStatus(jwtToken, memberGuid) {
  const shipmentApi = new apiFactory.ShipmentApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return shipmentApi.getUserShipmentStatusUsingGET(userAgent, memberGuid, opts);
}

function getMemberState(jwtToken, memberGuid, stateName) {
  const memberAdminApi = new apiFactory.MemberAdminApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return memberAdminApi.getUserStateUsingGET(userAgent, memberGuid, stateName, opts);
}

function updateMemberState(jwtToken, memberGuid, stateName, stateValue) {
  const memberAdminApi = new apiFactory.MemberAdminApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return memberAdminApi.updateUserStateUsingPOST(userAgent, memberGuid, stateName, stateValue, opts);
}

function sendInvoiceToMember(jwtToken, memberGuid) {
  const memberAdminApi = new apiFactory.MemberAdminApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  const items = [
    {
      article: 'monitor',
      count: 1
    }
  ];

  return memberAdminApi.sendInvoiceToMemberUsingPOST(userAgent, memberGuid, items, opts);
}

function getProgramsForPatient(jwtToken, patientGuid) {
  const programApi = new apiFactory.ProgramApi(client);

  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return programApi.getProgramsForPatientUsingGET(userAgent, patientGuid, opts);
}

function getMemberMonitors(jwtToken, memberGuid) {
  const memberApi = new apiFactory.MemberApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return memberApi.getMemberMonitorsUsingGETWithHttpInfo(userAgent, memberGuid, opts);
}

/**
 * Deactivating patient means starting the deactivation funnel
 */
function deactivePatientFromService(jwtToken, patientId, deactivationType) {
  const memberAdminApi = new apiFactory.MemberAdminApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  if (deactivationType) {
    opts.type = '"doNotSendReturnSlip"';
  }

  return memberAdminApi.deactivateMemberUsingPOST(patientId, opts);
}

/**
 * Reactivating patient means stopping deactivation funnel and including
 * patient into the service again
 */
function reactivatePatient(jwtToken, patientId) {
  const memberAdminApi = new apiFactory.MemberAdminApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return memberAdminApi.reactivateMemberUsingPOST(patientId, opts);
}

function getFunnelStatusForPatient(jwtToken, patientId) {
  const memberAdminApi = new apiFactory.MemberAdminApi(client);

  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return memberAdminApi.getDeactivationStatusUsingGET(userAgent, patientId, opts);
}

function getAccountSummary(jwtToken, from, to) {
  const accountingApi = new apiFactory.AccountingApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`,
    from,
    to
  };

  return accountingApi.getAccountSummaryUsingGET(userAgent, opts);
}

function getAccountTransactions(jwtToken, format, from, to) {
  return fetch(
    `${metadata.environments[env].apiUrl.replace(
      /\/+$/,
      ''
    )}/admin/modules/accounting/transactions?from=${from}&to=${to}`,
    {
      headers: {
        authorization: `Bearer ${jwtToken}`,
        accept: format
      }
    }
  );
}

function getBatches(jwtToken) {
  const accountingApi = new apiFactory.AccountingApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`,
    offset: 0,
    limit: 100000
  };

  return accountingApi.getBatchConfigUsingGet(userAgent, opts);
}

function createBatch(jwtToken, batch) {
  const accountingApi = new apiFactory.AccountingApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return accountingApi.addBatchConfigUsingPOST(userAgent, batch, opts);
}

function getPartnerUserTitles() {
  const partnerTitles = { ...apiFactory.PartnerUserTitle };
  delete partnerTitles.constructFromObject;
  return new Promise((resolve) => {
    resolve(Object.getOwnPropertyNames(partnerTitles));
  });
}

function getPartners(jwtToken) {
  const partnerAdministrationApi = new apiFactory.PartnerAdministrationApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return partnerAdministrationApi.getPartners(userAgent, opts);
}

function getPartnerUserExport(jwtToken, partnerId) {
  const getPartnerUserExportApi = new apiFactory.PartnerUserExportApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return getPartnerUserExportApi.getPartnerUserExportWithHttpInfo(userAgent, partnerId, opts);
}

function getPartner(jwtToken, partnerId) {
  const partnerAdministrationApi = new apiFactory.PartnerAdministrationApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return partnerAdministrationApi.getPartner(userAgent, partnerId, opts);
}

function createPartner(jwtToken, newPartner) {
  const partnerAdministrationApi = new apiFactory.PartnerAdministrationApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return partnerAdministrationApi.addPartner(userAgent, newPartner, opts);
}

function updatePartner(jwtToken, partnerId, updatedPartner) {
  const partnerAdministrationApi = new apiFactory.PartnerAdministrationApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return partnerAdministrationApi.updatePartner(userAgent, partnerId, updatedPartner, opts);
}

function addRecommendationInfoToPartner(jwtToken, partnerId, smsContent, recommendationReasons, smsReminderContent) {
  const partnerAdministrationApi = new apiFactory.PartnerAdministrationApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`,
    recommendedSmsContent: smsContent,
    enabledRecommendationReasons: recommendationReasons,
    recommendedSmsReminderContent: smsReminderContent
  };

  return partnerAdministrationApi.addPartnerRecommendationConfiguration(userAgent, partnerId, opts);
}

function addWebScreeningConfigurationToPartner(jwtToken, partnerId, configuration) {
  const partnerAdministrationApi = new apiFactory.PartnerAdministrationApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return partnerAdministrationApi.addPartnerWebScreeningConfiguration(userAgent, partnerId, configuration, opts);
}

function getPartnerFacilities(jwtToken, partnerId) {
  const partnerAdministrationApi = new apiFactory.PartnerAdministrationApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return partnerAdministrationApi.getFacilities(userAgent, partnerId, opts);
}

function getPartnerFacility(jwtToken, partnerId, facilityId) {
  const partnerAdministrationApi = new apiFactory.PartnerAdministrationApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return partnerAdministrationApi.getFacility(userAgent, partnerId, facilityId, opts);
}

function createPartnerFacility(jwtToken, partnerId, newFacility) {
  const partnerAdministrationApi = new apiFactory.PartnerAdministrationApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return partnerAdministrationApi.addFacility(userAgent, partnerId, newFacility, opts);
}

function updatePartnerFacility(jwtToken, partnerId, facilityId, updatedFacility) {
  const partnerAdministrationApi = new apiFactory.PartnerAdministrationApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return partnerAdministrationApi.updateFacility(userAgent, partnerId, facilityId, updatedFacility, opts);
}

function addVisitationInfoToPartnerFacility(
  jwtToken,
  partnerId,
  facilityId,
  distance,
  filename,
  instructionsFile,
  cancellationPolicy
) {
  const partnerAdministrationApi = new apiFactory.PartnerAdministrationApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`,
    cancellationPolicy
  };

  return partnerAdministrationApi.addFacilityVisitationInfo(
    userAgent,
    partnerId,
    facilityId,
    distance,
    filename,
    instructionsFile,
    opts
  );
}

function updateVisitationInfoForPartnerFacility(
  jwtToken,
  partnerId,
  facilityId,
  distance,
  filename,
  file,
  cancellationPolicy
) {
  const partnerAdministrationApi = new apiFactory.PartnerAdministrationApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`,
    distance,
    cancellationPolicy,
    filename: filename || undefined,
    file: file || new File([], 'dummy.pdf')
  };

  return partnerAdministrationApi.updateFacilityVisitationInfo(userAgent, partnerId, facilityId, opts);
}

function removePartnerFacility(jwtToken, partnerId, facilityId) {
  const partnerAdministrationApi = new apiFactory.PartnerAdministrationApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return partnerAdministrationApi.removeFacility(userAgent, partnerId, facilityId, opts);
}

function getPartnerUsers(jwtToken, partnerId, facilityId) {
  const partnerAdministrationApi = new apiFactory.PartnerAdministrationApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  if (facilityId) {
    opts.facilityId = facilityId;
  }

  return partnerAdministrationApi.getPartnerUsers(userAgent, partnerId, opts);
}

function getPartnerUser(jwtToken, partnerId, userId) {
  const partnerAdministrationApi = new apiFactory.PartnerAdministrationApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return partnerAdministrationApi.getPartnerUser(userAgent, partnerId, userId, opts);
}

function createPartnerUser(jwtToken, partnerId, facilityId, user) {
  const partnerAdministrationApi = new apiFactory.PartnerAdministrationApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  if (facilityId) {
    opts.facilityId = facilityId;
  }

  return partnerAdministrationApi.addPartnerUser(userAgent, partnerId, user, opts);
}

function updatePartnerUser(jwtToken, partnerId, userId, updatedUser) {
  const partnerAdministrationApi = new apiFactory.PartnerAdministrationApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return partnerAdministrationApi.updatePartnerUser(userAgent, partnerId, userId, updatedUser, opts);
}

function removePartnerUser(jwtToken, partnerId, userId) {
  const partnerAdministrationApi = new apiFactory.PartnerAdministrationApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return partnerAdministrationApi.removePartnerUser(userAgent, partnerId, userId, opts);
}

function removePartner(jwtToken, partnerId) {
  const partnerAdministrationApi = new apiFactory.PartnerAdministrationApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return partnerAdministrationApi.removePartner(userAgent, partnerId, opts);
}

function getPartnerFacilityInstructionsFile(jwtToken, partnerId, facilityId) {
  const partnerApi = new apiFactory.PartnerApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return partnerApi.getFacilityInstructionsPdfWithHttpInfo(userAgent, partnerId, facilityId, opts);
}

function generatePartnerSetupCode(jwtToken, partnerId, facilityId) {
  const partnerAdministrationApi = new apiFactory.PartnerAdministrationApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return partnerAdministrationApi.generatePartnerSetupCode(userAgent, partnerId, facilityId, opts);
}

function updatePartnerSetupCode(jwtToken, partnerId, facilityId, setupCode) {
  const partnerAdministrationApi = new apiFactory.PartnerAdministrationApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return partnerAdministrationApi.updatePartnerSetupCode(
    userAgent,
    partnerId,
    facilityId,
    setupCode.id,
    setupCode,
    opts
  );
}

function sendPartnerSetupCode(jwtToken, partnerId, facilityId, setupCodeId) {
  const partnerAdministrationApi = new apiFactory.PartnerAdministrationApi(client);
  const opts = {
    authorization: `Bearer ${jwtToken}`
  };

  return partnerAdministrationApi.sendPartnerSetupCodeToFacility(userAgent, partnerId, facilityId, setupCodeId, opts);
}

function getPatientPrograms(jwtToken, patientId) {
  return fetch(`${basePath}/admin/${patientId}/program`, {
    headers: {
      authorization: `Bearer ${jwtToken}`
    }
  });
}

function updatePatientProgram(jwtToken, patientId, programId, action) {
  return fetch(`${basePath}/admin/${patientId}/program/${programId}/${action}`, {
    headers: {
      authorization: `Bearer ${jwtToken}`
    },
    method: 'PATCH'
  });
}

function getSupportChatMessages(jwtToken, patientId, fromTimeStamp) {
  return fetch(`${basePath}/admin/chat/SUPPORT/${patientId}${fromTimeStamp ? `?fromTs=${fromTimeStamp}` : ''}`, {
    headers: {
      authorization: `Bearer ${jwtToken}`
    }
  });
}

function sendSupportChatMessage(jwtToken, patientId, message) {
  return fetch(`${basePath}/admin/chat/SUPPORT/${patientId}`, {
    headers: {
      authorization: `Bearer ${jwtToken}`,
      'Content-Type': 'application/json'
    },
    method: 'POST',
    body: JSON.stringify(message)
  });
}

const apiMethods = {
  logInTest,
  challenge,
  login,
  refreshToken,
  getMembers,
  getMember,
  updateMember,
  updateMemberAnamnesisAnswer,
  getMemberAnamnesisQuestion,
  getMemberDevices,
  getSystemUsers,
  getSystemUser,
  updateSystemUser,
  getRegions,
  getInvoiceReport,
  getReferralDocuments,
  getAuthorities,
  bulkSendChatMessage,
  postHandover,
  deleteHandover,
  updateHandover,
  getHandovers,
  postBulkReferrals,
  getMemberComment,
  updateMemberComment,
  getFile,
  getMemberJiraIssues,
  postMemberJiraIssue,
  getJiraIssueTypes,
  getShipmentItems,
  scheduleShipments,
  addManualShipment,
  deleteShipment,
  delayShipment,
  getReferrals,
  getScheduledCommunication,
  updateScheduledCommunication,
  deleteScheduledCommunication,
  getCannedResponses,
  updateCannedResponse,
  postCannedResponse,
  deleteCannedResponse,
  getTemplateTags,
  sendEmail,
  getCaregiverStatistics,
  getDoctorPatientTotals,
  getMemberChatMessages,
  getMemberJournal,
  postAssignmentPriority,
  deleteAssignmentPriority,
  getMemberSparData,
  getDoctorFinalNoteBreakdown,
  updateMemberPaymentExemption,
  deleteMemberPaymentExemption,
  postMemberPaymentExemption,
  getRemunerationScheme,
  getMemberPaymentHistory,
  getInvoice,
  assignCaregiver,
  uploadFile,
  tagPersonalNumbersWithSource,
  createUser,
  getMemberShipmentStatus,
  getMemberState,
  updateMemberState,
  sendInvoiceToMember,
  getProgramsForPatient,
  getMemberMonitors,
  deactivePatientFromService,
  reactivatePatient,
  getAccountSummary,
  getAccountTransactions,
  getBatches,
  createBatch,
  getPartnerUserTitles,
  getPartners,
  getPartner,
  getPartnerUserExport,
  createPartner,
  updatePartner,
  addRecommendationInfoToPartner,
  removePartner,
  getPartnerFacilities,
  getPartnerFacility,
  createPartnerFacility,
  updatePartnerFacility,
  addVisitationInfoToPartnerFacility,
  updateVisitationInfoForPartnerFacility,
  removePartnerFacility,
  getPartnerUsers,
  getPartnerUser,
  createPartnerUser,
  updatePartnerUser,
  removePartnerUser,
  getPartnerFacilityInstructionsFile,
  generatePartnerSetupCode,
  updatePartnerSetupCode,
  sendPartnerSetupCode,
  getFunnelStatusForPatient,
  getPatientPrograms,
  updatePatientProgram,
  getSupportChatMessages,
  sendSupportChatMessage,
  addWebScreeningConfigurationToPartner
};

export default apiMethods;
