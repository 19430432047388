import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import { Translate } from 'react-redux-i18n';
import LoaderButton from '../LoaderButton';
import './Modal.scss';

type ModalProps = {
  children: React.ReactNode;
  headerI18nKey: string;
  actionI18nKey: string;
  header?: string;
  visible: boolean;
  onClose: () => void;
  onActionCompleted: () => void;
  actionCompletable: boolean;
  actionCompleting?: boolean;
  size?: 'large' | 'medium' | 'small' | 'x-small' | 'auto';
  contentClass?: string;
  additionalFooterContent?: React.ReactNode;
  allowEnterCompletion?: boolean;
};

class Modal extends Component<ModalProps> {
  constructor(props: ModalProps) {
    super(props);

    this.keydownListener = this.keydownListener.bind(this);
  }

  static defaultProps = {
    size: 'small'
  };

  keydownListener(e: KeyboardEvent) {
    if (this.props.visible) {
      if (e.key === 'Escape' && this.props.visible) {
        this.props.onClose();
        return;
      }

      if (e.key === 'Enter' && this.props.actionCompletable && this.props.allowEnterCompletion) {
        this.props.onActionCompleted();
      }
    }
  }

  componentDidMount() {
    window.addEventListener('keydown', this.keydownListener);
  }

  componentWillUnmount() {
    window.removeEventListener('keydown', this.keydownListener);
  }

  render() {
    const {
      children,
      headerI18nKey,
      header,
      actionI18nKey,
      visible,
      onClose,
      onActionCompleted,
      actionCompletable,
      size,
      contentClass,
      additionalFooterContent
    } = this.props;

    if (!visible) {
      return null;
    }

    return ReactDOM.createPortal(
      <div className="modal is-active">
        <div className="modal-background" onClick={onClose}></div>
        <div className={`modal-content modal-content__${size}`}>
          <div className="handle"></div>
          <div className="columns modal-content__header">
            <div className="column is-10 no-padding">
              <h2>{header ? header : <Translate value={headerI18nKey} />}</h2>
            </div>
            <div className="column no-padding">
              <button className="modal-close-button" aria-label="close" onClick={onClose}></button>
            </div>
          </div>
          <div className={`modal-content__children ${contentClass || ''}`}>{children}</div>
          {onActionCompleted ? (
            <div className="modal-content__footer">
              {additionalFooterContent && additionalFooterContent}
              <div className="columns">
                <div className="column no-padding">
                  <div className="modal-action">
                    <LoaderButton
                      isLoading={this.props.actionCompleting}
                      onClick={onActionCompleted}
                      disabled={!actionCompletable}
                    >
                      <Translate value={actionI18nKey || headerI18nKey} />
                    </LoaderButton>
                  </div>
                </div>
              </div>
            </div>
          ) : null}
        </div>
      </div>,
      document.body
    );
  }
}

export default Modal;
